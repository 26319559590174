import React from 'react';
import './Title.css';
import titleInfo from './titleInfo';

const Title = () => (
    <React.Fragment>
        <div className={'title'}>{titleInfo.title}</div><hr />
    </React.Fragment>
);

export default Title;