import React from 'react';
import './CheckInForm.scss';
import { Popup } from 'devextreme-react/popup';
import { Button, TextArea } from 'devextreme-react';
import { Validator, RequiredRule } from 'devextreme-react/validator';
import { connect, Provider } from "react-redux";
import { store } from '../../_helpers';
import { userActions } from '../../_actions/user.actions';


require('dotenv').config({ debug: false })
const URL = process.env.REACT_APP_AUTH_API_URL + '/api/users/CheckIn';

class CheckInForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popupVisible: false,
            value: '',
            charactersLeft: 255,
            employeeId:''
        };

        //debugger;
        this.unsubscribeProfileListener = store.subscribe(this.listenForDispatch.bind(this));   
       
    }

    listenForDispatch() {
        //debugger;
        const profile = store.getState().userrole.profile;
        if (profile) {

            this.unsubscribeProfileListener();
            // debugger;
            this.setState({ employeeId: profile[0].EmployeeId });
            if (!profile[0].IsCheckedIn) {
                this.setState({ popupVisible: true });
            }

        }
    }

    componentDidMount() {

    }

    hidePopup = () => {
        this.setState({ popupVisible: false });
    }

    handleCheckIn = (e) => {
        //debugger;
        e.event.stopPropagation();
        if (!e.validationGroup.validate().isValid) {
            return;
        }

        const myObj = {
            Message: this.state.value,
            EmployeeId: this.state.employeeId
        };

        const values = JSON.stringify(myObj);

        store.dispatch(userActions.checkIn('checkininsert',values));

        return new Promise((resolve, reject) => {
            const unsubscribe = store.subscribe(() => {

                const { checkInCompleted, ...extraParams } = store.getState().userrole;
                //debugger;
                unsubscribe();

                if (checkInCompleted) {
                    resolve();
                    this.setState({ popupVisible: false });
                } else {
                    reject(extraParams.message);
                }
                
            });
        });

    }

    onTextAreaValueChanged = (e) => {
        this.setState({
            value: e.event.target.value
        });
    }

    onTextAreaKeyUp = (e) => {
        const charactersLeft = 255 - (e.event.target.value).length;
        this.setState({ charactersLeft: charactersLeft });
    }

    render() {

        return (
            <Provider store={store}>
                <Popup
                    visible={this.state.popupVisible}
                    onHiding={this.hidePopup}
                    dragEnabled={true}
                    closeOnOutsideClick={false}
                    showCloseButton={true}
                    showTitle={true}
                    title={'Your Action is Needed'}
                    width={320}
                    height={250}
                    className={'CheckInPopup'}
                >
                    <p>
                        Your supervisor has required you to check-in.
                    </p>
                    <span className={'note'}>
                        Enter Check-in Short Message below (optional):
                    </span>
                    <TextArea
                        height={50}
                        maxLength={255}
                        value={this.state.value}
                        onValueChanged={this.onTextAreaValueChanged}
                        onKeyUp={this.onTextAreaKeyUp} >
                        <Validator>
                            <RequiredRule message={'Short Message is required.'} />
                        </Validator>
                    </TextArea>

                    <span className={'charactersleft'}>
                        {this.state.charactersLeft} characters left
                    </span>
                    <br /><br />
                    <Button
                        type={'default'}
                        width={100}
                        height={30}
                        onClick={this.handleCheckIn}
                    >
                        Check-in
                        </Button> Press Check-in To Proceed.
                </Popup>
            </Provider>

        );
    }

}

function mapStateToProps(state) {
    return {
        state: state.userrole
    };
}

const connectedCheckInForm = connect(mapStateToProps)(CheckInForm);
export { connectedCheckInForm as CheckInForm };


