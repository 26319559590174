import {
    //Broadcast_GRID_STATE_LOAD,
    //Broadcast_GRID_STATE_LOADED,

    Broadcast_Editor_STATE_LOAD,
    Broadcast_Editor_STATE_LOADED,
    Broadcast_Editor_STATE_LOAD_ERROR,
    Broadcast_Editor_STATE_INSERT,
    Broadcast_Editor_STATE_INSERTED,
    Broadcast_Editor_STATE_INSERT_ERROR,
    Broadcast_Editor_STATE_UPDATE,
    Broadcast_Editor_STATE_UPDATED,
    Broadcast_Editor_STATE_UPDATE_ERROR,
    Broadcast_Editor_STATE_REMOVE,
    Broadcast_Editor_STATE_REMOVED,
    Broadcast_Editor_STATE_REMOVE_ERROR
} from "../_constants/action-types"

const broadcastGridInitialState = {
    BroadcastMessages: [],
    BroadCastTypes: [],
    BroadCastGroups: [],
    requests: [],
    events: [],
    refreshMode: 'reshape',
    updated: false,
    completed: false,
    loadbroadcasteditor:[]
};

function broadcastGridReducer(state = broadcastGridInitialState, action) {
    //debugger;
  
    //if (action.type === Broadcast_GRID_STATE_LOAD) {
    //    //debugger;
    //    return {
    //        ...state,
    //        [action.partialStateName]: action.partialStateValue,
    //    };
    //}
    //if (action.type === Broadcast_GRID_STATE_LOADED) {
    //    return Object.assign({}, state, {
    //        loaddata: action.loaddata,
    //        completed: true,
    //        succeeded: true
    //    });
    //}

    if (action.type === Broadcast_Editor_STATE_LOAD) {
        //debugger;
        return {
            ...state,
            [action.partialStateName]: action.partialStateValue,
        };
    }
    if (action.type === Broadcast_Editor_STATE_LOADED) {
        //debugger;
        return Object.assign({}, state, {
            loadbroadcasteditor: action.loadbroadcasteditor,
            completed: true,
            succeeded: true
        });
    }
    if (action.type === Broadcast_Editor_STATE_LOAD_ERROR) {
        //debugger;
        return Object.assign({}, state, {
            [action.partialStateName]: action.partialStateValue,
            completed: true,
            succeeded: false,
            apiError: true,
            message: 'Operation failed!',
            loading: false,
        });
    }

    if (action.type === Broadcast_Editor_STATE_INSERT) {
        //debugger;
        return {
            ...state,
            [action.partialStateName]: action.partialStateValue,
        };
    }
    if (action.type === Broadcast_Editor_STATE_INSERTED) {
        //debugger;
        return Object.assign({}, state, {
            [action.partialStateName]: action.partialStateValue,
            insertbroadcasteditor: action.insertbroadcasteditor,
            completed: true,
            succeeded: true
        });
    }
    if (action.type === Broadcast_Editor_STATE_INSERT_ERROR) {
        //debugger;
        return Object.assign({}, state, {
            [action.partialStateName]: action.partialStateValue,
            completed: true,
            succeeded: false,
            apiError: true,
            message: 'Operation failed!'

        });
    }

    if (action.type === Broadcast_Editor_STATE_UPDATE) {
        return {
            ...state,
            [action.partialStateName]: action.partialStateValue,
        };
    }
    if (action.type === Broadcast_Editor_STATE_UPDATED) {
        //debugger;
        return Object.assign({}, state, {
            [action.partialStateName]: action.partialStateValue,
            updated: true,  // for testing purpose only
            completed: true,
            succeeded: true
        });
    }
    if (action.type === Broadcast_Editor_STATE_UPDATE_ERROR) {
        //debugger;
        return Object.assign({}, state, {
            [action.partialStateName]: action.partialStateValue,
            updated: false, // for testing purpose only
            completed: true,
            succeeded: false,
            apiError: true,
            message: 'Operation failed!'
        });
    }
    if (action.type === Broadcast_Editor_STATE_REMOVE) {
        return {
            ...state,
            [action.partialStateName]: action.partialStateValue,
        };
    }
    if (action.type === Broadcast_Editor_STATE_REMOVED) {
        return Object.assign({}, state, {
            [action.partialStateName]: action.partialStateValue,
            completed: true,
            succeeded: true
        });
    }
    if (action.type === Broadcast_Editor_STATE_REMOVE_ERROR) {
        return Object.assign({}, state, {
            [action.partialStateName]: action.partialStateValue,
            completed: true,
            succeeded: false,
            apiError: true,
            message: 'Operation failed!'
        });
    }
    return state;
}

export default broadcastGridReducer;