import React from 'react';
import './broadcast.scss';
import Button from 'devextreme-react/button';
import TextArea from 'devextreme-react/text-area';
import CustomStore from 'devextreme/data/custom_store';
import { TagBox } from 'devextreme-react/tag-box';
import notify from 'devextreme/ui/notify';

//import { fetchBroadcastData, loadBroadcastData, updateBroadcastData, insertBroadcastData, removeBroadcastData, getBroadcastTypes, getBroadcastGroups } from "../../_actions/broadcast.actions";
import { insertBroadcastData_FormSMS, getBroadcastGroups} from "../../_actions/broadcast.actions";
import { connect, Provider } from "react-redux";
//import store from "../../_store/broadcast-formsms-store"
import { debounce } from 'redux-saga/effects';
import { store } from '../../_helpers';


const URL = 'https://localhost:44328/api/Broadcasts'; //'api/Broadcasts';
const URLg = 'https://localhost:44328/api/Groups'
const URLt = 'https://localhost:44328/api/Types'

class broadcastsms extends React.PureComponent {
    //constructor(props) {
    //    super(props);

    //    this.state = {

    //        //BroadCastGroups: new CustomStore({
    //        //    Value: 'GroupId',
    //        //    loadMode: 'raw',
    //        //    load: () => this.sendRequest('api/Groups/GetGroupList')
    //        //}),
    //        BroadCastGroups: new CustomStore({
    //            Value: 'GroupId',
    //            loadMode: 'raw',
    //            load: () => getBroadcastGroups(URLg)
    //        }),
    //        loading: true ,
    //        broadcastmessage: '',
    //        selectedgroup: []

    //    };

    //    this.onMultiTagPreparing = this.onMultiTagPreparing.bind(this);
    //    this.handleSubmit = this.handleSubmit.bind(this);
    //    this.onTextAreaValueChanged = this.onTextAreaValueChanged.bind(this);
    //    this.onTagBoxValueChanged = this.onTagBoxValueChanged.bind(this);
    //    this.onValueChanged = this.onValueChanged.bind(this);
    //}
    constructor(props) {
        super(props);

        this.state = {

            BroadCastGroups: new CustomStore({
                Value: 'GroupId',
                loadMode: 'raw',
                load: () => getBroadcastGroups(URLg)
            }),

            requests: [],
            events: [],
            refreshMode: 'reshape'
            //smsSubmitted: false,
            //successOK : false
        };
        this.handleOptionChanged = this.handleOptionChanged.bind(this);
        this.onMultiTagPreparing = this.onMultiTagPreparing.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onTextAreaValueChanged = this.onTextAreaValueChanged.bind(this);
        this.onTagBoxValueChanged = this.onTagBoxValueChanged.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
    };
    onValueChanged(e) {
        this.setState({
            value: e.value
        });
    }


    //sendRequest(url, method, data) {
    //    method = method || 'GET';
    //    data = data || {};

    //    if (method === 'GET') {
    //        return fetch(url, {
    //            method: method,
    //            credentials: 'include'
    //        }).then(result => result.json().then(json => {
    //            if (result.ok) {
    //                //  alert(JSON.stringify(json));
    //                return json;
    //            }
    //            throw json.Message;
    //        }));
    //    }

    //    const params = Object.keys(data).map((key) => {
    //        return `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`;
    //    }).join('&');

    //    return fetch(url, {
    //        method: method,
    //        body: params,
    //        headers: {
    //            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    //        },
    //        credentials: 'include'
    //    }).then(result => {
    //        if (result.ok) {
    //            return result.text().then(text => text && JSON.parse(text));
    //        } else {
    //            //  console.log(result.text());
    //            return result.json().then(json => {
    //                throw json.Message;
    //            });

    //        }
    //    });
    //}
    onMultiTagPreparing(args) {
        const selectedItemsLength = args.selectedItems.length;
        const totalCount = 5;

        if (selectedItemsLength < totalCount) {
            args.cancel = true;
        } else {
            args.text = `All selected (${selectedItemsLength})`;
        }

    }

    handleSave(event) {
        event.preventDefault();
        const data = new FormData(event.target);
    }
    onTextAreaValueChanged(e) {
        this.setState({
            broadcastmessage: e.value
        });
    }
    onTagBoxValueChanged(e) {
     //    alert(e.value);
        this.setState({
            selectedgroup: e.value
        });
    }

    handleSubmit(e) {

        const { dispatch } = this.props;
        var myObj = { broadcastmessage: '', selectedgroup: [] };
        myObj.broadcastmessage = this.state.broadcastmessage;
        myObj.selectedgroup = this.state.selectedgroup;
       
        var values = JSON.stringify(myObj);
       
        
        dispatch(insertBroadcastData_FormSMS(('insertsms', { values: values })));

        debugger;
        console.log(`Insert New Record`);


        return new Promise((resolve, reject) => {
            const unsubscribe = store.subscribe(() => {
                debugger;
                const { completed, succeeded, ...extraParams } = store.getState().formSMSReducer;
               
                if (!succeeded) return;

                unsubscribe();

                if (succeeded) {
                    resolve();
                } else {
                    reject(extraParams.message);
                }
            });
        });
        //this.sendRequest(`${URL}/InsertBroadcastMessagesByForm`, 'POST', {
        //    values: values //JSON.stringify(values)
        //});
        //this.props._onInsert({
        //    values: JSON.stringify(myObj)

        //});
        debugger;
        notify({
            message: this.state.successOK,
            position: {
                my: 'center top',
                at: 'center top'
            }
        }, 'success', 3000);
        e.preventDefault();
    }
    logEvent(eventName) {
        this.setState((state) => {
            return { events: [eventName].concat(state.events) };
        });
    }

    clearEvents() {
        this.setState({ events: [] });
    }

    componentDidMount() {
        // calling the new action creator

    };
    componentDidUpdate() {

    };
    handleOptionChanged(event) {
        if (event.fullName.indexOf('sortOrder') > 0)
            this.props._onSorting();
    };

    render() {
        const { BroadCastGroups, smsSubmitted, successOK} = this.state;
        return (
            <form onSubmit={this.handleSubmit} >
                <div className={'dx-fieldset'}>
                    <div className={'dx-field'}>
                        <p >Enter SMS Message:</p>
                    </div>
                    <div className={'dx-field'}>
                        <TextArea width="500" height="150"
                            value={this.state.broadcastmessage}
                            onValueChanged={this.onTextAreaValueChanged}
                        //onValueChanged={e => this.setState({ broadcastmessage: this.state.value })}
                        />

                    </div>
                    <div className={'dx-field'}>
                        <p >Broadcast Message to Group:</p>
                    </div>

                    <div className={'dx-field'}>
                        <div className={'dx-field-value'}>
                            <TagBox width="400"
                                dataSource={BroadCastGroups}
                                // defaultValue={[1, 2, 3, 4]}
                                showSelectionControls={true}
                                maxDisplayedTags={3}
                                displayExpr={'GroupName'}
                                valueExpr={'Id'}
                                selectAllMode={'allPages'}
                                onValueChanged={this.onTagBoxValueChanged}
                            />
                        </div>
                    </div>
                    <div className={'dx-field'}>
                        <Button
                            width={100}
                            text={'Send'}
                            useSubmitBehavior={true}
                        ></Button>
                    </div>
                </div>
            </form>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {

    };
}
function mapStateToProps(state) {
    //const { successOK } = state;
    return {
        state: state.formSMSReducer
    };
}
//const SMSForm = connect(mapStateToProps, mapDispatchToProps)(broadcastsms);
const SMSForm = connect(mapStateToProps)(broadcastsms);
export default () => (
    <React.Fragment>      
        <div className={'content-block'}>
            <div className={'dx-card responsive-paddings'}>
                <Provider store={store}>
                    <SMSForm />
                </Provider>
            </div>
        </div>
    </React.Fragment>
);
//export default broadcastsms;
