import React, { Component } from "react";
import PropTypes from "prop-types";
import "./Accordion.scss";
import { CSSTransition } from "react-transition-group";

class AccordionSection extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  };

  onClick = () => {
    this.props.onClick(this.props.label);
  };

  render() {
    const {
      key,
      onClick,
      props: { isOpen, label }
    } = this;

    return (
        <div key={key} className="accordion-section">
            <div onClick={onClick} className="accordion-title">
                {label}
                <div className="accordion-triangle">
                    {!isOpen && <i className={'fas fa-chevron-up'} />}
                    {isOpen && <i className={'fas fa-chevron-down'} />}
                </div>
            </div>
            
            {isOpen && (
              <CSSTransition in={isOpen} appear={true} timeout={500} classNames="fade">
                <div className={`accordion-content ${isOpen ? "isOpen" : ''}`}>
                    {this.props.children}
                </div>
                </CSSTransition>
            )}
        </div>
    );
  }
}

export default AccordionSection;
