import React from 'react';
import './AnnouncementDisplay.scss';
import 'devextreme/data/odata/store';
import List from 'devextreme-react/list';

require('dotenv').config({ debug: false })

export default class AnnouncementDisplay extends React.Component {

    dataSource = {
        store: {
            type: 'odata',
            key: 'ID',
            //url: 'http://localhost:4633/odata/AnnouncementOData',
            //url: 'http://atc-nash-dev/atceapi-announcement/odata/AnnouncementOData'
            //url: 'https://atceapi-announcement.azurewebsites.net/odata/AnnouncementOData'
            url: process.env.REACT_APP_ANNOUNCEMENT_API_URL +'/odata/AnnouncementOData'
        },
        select: [
            'ID',
            'Title',
            'Message',
            'DateTimePosted',
            'isActive'
        ],
        filter: [['isActive', '=', true], ['TypeID', '=', 1]]
    };

    dFormat = (dateObj) => {
        var year = dateObj.getFullYear();
        var month = dateObj.getMonth();
        var day = dateObj.getDate();
        var hours = dateObj.getHours()
        var minutes = dateObj.getMinutes()
        var seconds = dateObj.getSeconds();
        if (month < 10) {
            month = "0" + month;
        }
        if (day < 10) {
            day = "0" + day;
        }
        if (hours < 10) {
            hours = "0" + hours;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        if (seconds < 10) {
            seconds = "0" + seconds;
        }

        return month + "/" + day + "/" + year + " " + hours + ":" + minutes + ":" + seconds;
    }

    render() {
        const Announcement = (item) => {
            return (
                <div>
                    <div className={'announcementTitle'}>
                        {item.Title}
                    </div>
                    <div>
                        {
                            this.dFormat(item.DateTimePosted)
                        }
                    </div>
                    <div className={'announcementContent'}>
                        {item.Message}
                    </div>

                </div>
            );
        }

        return (
            <div
                className="announcement-display">
                <List
                    className='list'
                    dataSource={this.dataSource}
                    itemRender={Announcement}
                    activeStateEnabled={false}
                    focusStateEnabled={false}
                    hoverStateEnabled={false}
                    noDataText={'There is no public announcement. Please check back. Thank you. '}
                    />
                
            </div>
        );
    }


}
