import "./help01.scss";
import React from 'react';
import { Button, TextArea } from 'devextreme-react';
import notify from 'devextreme/ui/notify';
import 'devextreme-react/autocomplete';

class Help01 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.onClickSubmitHelpDesk = this.onClickSubmitHelpDesk.bind(this);
    }

    render() {
        
        return (
            <React.Fragment>
                <div className={'content-container'}>
                    <div className={'info-text'}>
                        <h2>ATC Helpdesk</h2>
                        <p>This page will have important information from the helpdesk related to ATC Emergency.
                        </p>
                        <Button
                                type={'success'}
                                text={'Click to Submit Helpdesk Ticket'}
                                height={40}
                                onClick={this.onClickSubmitHelpDesk}
                            />
                        <p><a href="javascript:void();" onClick={this.onClickSubmitHelpDesk}>Click to Submit Helpdesk Ticket</a></p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    onClickSubmitHelpDesk(args) {
        //debugger;
        this.props.changeTabIndex(1);
    }
}

export default Help01;