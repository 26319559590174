import { userConstants } from '../_constants';

//let userRole = localStorage.getItem('role');
//const initialUserRoleState = {};

export function userrole(state = { userAbility: [], userAbilityRetrieved:false }, action) {
    //debugger;
    switch (action.type) {
        //case userConstants.GETROLE_REQUEST:
        //    return {
        //        ...state,
        //        role: "",
        //        roleRetrieved: false
        //    };
        //case userConstants.GETROLE_SUCCESS:
        //    //debugger;
        //    return {
        //        ...state,
        //        role: action.role,
        //        roleRetrieved: true
        //    };
        //case userConstants.GETROLE_FAILURE:
        //    return {};
        case userConstants.GETPROFILE_REQUEST:
            return {
                ...state,
                profile: "",
                profileRetrieved: false
            };
        case userConstants.GETPROFILE_SUCCESS:
            //debugger;
            return {
                ...state,
                profile: action.profile,
                profileRetrieved: true
            };
        case userConstants.GETPROFILE_FAILURE:
            return {};

        case userConstants.LOGOUT_SUCCESS:
            return {};
        case userConstants.LOGOUT_FAILURE:
            return {};
        case userConstants.USERS_GETABILITY_REQUEST:
            //debugger;
            return {
                ...state,
                userName: action.userName,
                userAbilityRetrieved: false,
                loggingIn: true
            };
        case userConstants.USERS_GETABILITY_SUCCESS:
            //debugger;
            return {
                ...state,
                userAbility: action.userAbility,
                userAbilityRetrieved: true,
                loggedIn: true
            };
        case userConstants.USERS_GETABILITY_FAILURE:
            return {
                ...state,
                userAbility: [],
                userAbilityRetrieved: false,
                message:'Get Ability Failed!'
            };
        case userConstants.CHECKIN_INSERT:
            //debugger;
            return {
                ...state,
                [action.partialStateName]: action.partialStateValue,
                checkInCompleted: false
            };
        case userConstants.CHECKIN_INSERTED:
            //debugger;
            return Object.assign({}, state, {
                [action.partialStateName]: action.partialStateValue,
                checkInCompleted: true,
            });
        case userConstants.CHECKIN_FAILURE:
            //debugger;
            return Object.assign({}, state, {
                [action.partialStateName]: action.partialStateValue,
                checkInCompleted: false,
                message:'Check In Failed!'
            });
        default:
            return state
    }
}