import React from 'react'

const loadAuthenticatedApp = () => import('./App-Authenticated')
const AuthenticatedApp = React.lazy(loadAuthenticatedApp)
const UnauthenticatedApp = React.lazy(() => import('./App-Unauthenticated'))

function AppNew() {
    const user = false   //mng - check if login
    //debugger;
    // pre-load the authenticated side in the background while the user's
    // filling out the login form.
    React.useEffect(() => {
        loadAuthenticatedApp()
    }, [])
    return (
        <React.Suspense fallback={<h1>Loading ...</h1>}>
            {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
        </React.Suspense>
    )
}

export default AppNew