import React from 'react';
import { DataGrid, Column, Scrolling, Lookup, Grouping, Pager, Paging, HeaderFilter, ColumnChooser } from 'devextreme-react/data-grid';

import CustomStore from 'devextreme/data/custom_store';
import { store } from '../../_helpers';
import { connect, Provider } from "react-redux";
import defineAbilityFor from '../../config/abilityrule';
import { loadCheckinsData } from "../../_actions/broadcast.actions";
import * as AspNetData from 'devextreme-aspnet-data-nojquery';

import './list.scss';

require('dotenv').config({ debug: false })
const url = process.env.REACT_APP_BROADCAST_API_URL + '/api/Broadcasts';

const dataSource = AspNetData.createStore({
    key: 'Id',
    loadUrl: url+'/ListCheckins'
})

function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
}


export class GridW extends React.Component {
    constructor(props) {

        super(props);
        const ability = defineAbilityFor(store.getState().userrole.userAbility);
        
        this.state = {
            checkinsData: new CustomStore({
                key: 'Id',
                //loadMode: 'raw',
                load: this.loadCheckinsData //TODO
            }),
            updated: false,
            loadcheckinsdata: [],
            ability: ability
        }

    }
    msgDateFormat = { year: '2-digit', month: 'narrow', day: '2-digit' };

    loadCheckinsData(loadOptions) {
        let params = '?';
        [
            'skip',
            'take',
            'requireTotalCount',
            'requireGroupCount',
            'sort',
            'filter',
            'totalSummary',
            'group',
            'groupSummary'
        ].forEach(function (i) {
            if (i in loadOptions && isNotEmpty(loadOptions[i])) { params += `${i}=${JSON.stringify(loadOptions[i])}&`; }
        });
        params = params.slice(0, -1);

        var response = store.dispatch(loadCheckinsData('loadcheckinsdata', params.replace(/\"/g, "")));

        console.log(`Load Data`);
        return new Promise((resolve, reject) => {
            const unsubscribe = store.subscribe(() => {
                const { completed, succeeded, loadcheckinsdata, ...extraParams } = store.getState().checkinsGridWReducer;

                if (!completed) return;

                unsubscribe();
                if (succeeded) {
                    //debugger;
                    resolve(loadcheckinsdata);
                } else {
                    reject(extraParams.message);
                }
            });
        });
    }
    render() {
        const {
            checkinsData, ability } = this.state

        return (
            <React.Fragment>
                <DataGrid
                    id={'gridCheckins'}
                    showBorders={true}
                    dataSource={dataSource}
                    remoteOperations={true}
                    wordWrapEnabled={true}
                    columnHidingEnabled={true}
                >
                    <Scrolling mode="virtual" rowRenderingMode="virtual" scrollByThumb={true} showScrollbar="OnScroll" />
                    <Paging pageSize="5" />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} />
                    <HeaderFilter visible={true} allowSearch={true} />
                    <ColumnChooser enabled={false} mode="select" />

                    <Column dataField="DateTimeCheckin" dataType={"date"} caption="Received" format={"MMM d yyyy hh:mm:ss"} />
                    <Column dataField="Message" caption="Message" cssClass={'colMsg'} />
                    <Column dataField="Firstname" caption="Firstname" hidingPriority={2}/>
                    <Column dataField="Lastname" caption="Lastname" hidingPriority={2}/>
                    <Column dataField="Division" caption="Division" hidingPriority={1}/>
                    <Column dataField="Mobile" caption="Mobile" hidingPriority={0} />
                </DataGrid>
            </React.Fragment>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return {};
}
function mapStateToProps(state) {
    return {
        state: state.checkinsGridWReducer
    };
}

const Grid = connect(mapStateToProps, mapDispatchToProps)(GridW);

export default () => (
    <React.Fragment>
        <div className={'content-block'}>

            <div className={'dx-card responsive-paddings'}>
                <div className={'title-padding'}>
                    <span className={'pagetitle'}>List of Check-ins</span>
                </div>
                
                    <GridW />


            </div>
        </div>
    </React.Fragment>
)