import {
    ANNOUNCEMENT_GRID_STATE_CHANGE,
    ANNOUNCEMENT_GRID_STATE_LOAD,
    ANNOUNCEMENT_GRID_STATE_LOADED,
    ANNOUNCEMENT_GRID_STATE_LOAD_ERROR,
    ANNOUNCEMENT_GRID_STATE_INSERT,
    ANNOUNCEMENT_GRID_STATE_INSERTED,
    ANNOUNCEMENT_GRID_STATE_INSERT_ERROR,
    ANNOUNCEMENT_GRID_STATE_UPDATE,
    ANNOUNCEMENT_GRID_STATE_UPDATED,
    ANNOUNCEMENT_GRID_STATE_UPDATE_ERROR,
    ANNOUNCEMENT_GRID_STATE_REMOVE,
    ANNOUNCEMENT_GRID_STATE_REMOVED,
    ANNOUNCEMENT_GRID_STATE_REMOVE_ERROR,
    ANNOUNCEMENT_PRIVLIST_STATE_LOAD,
    ANNOUNCEMENT_PRIVLIST_STATE_LOADED,
    ANNOUNCEMENT_PRIVLIST_STATE_LOAD_ERROR,
    ANNOUNCEMENT_TYPESTATE_LOAD,
    ANNOUNCEMENT_TYPE_STATE_LOADED,
    ANNOUNCEMENT_TYPE_STATE_LOAD_ERROR,
} from "../_constants/action-types"

import { takeEvery, call, put } from "redux-saga/effects";
import { sendRequest } from "../_helpers/util"

require('dotenv').config({debug: false})
//require('dotenv').config({ path: '/src/config', debug: true})

//const url = "http://localhost:4633/api/v1/Announcement";
//const url = "http://atc-nash-dev/atceapi-announcement/api/v1/Announcement";
const url = process.env.REACT_APP_ANNOUNCEMENT_API_URL + "/api/v1/Announcement";

export function* worker_announcement_grid(action) {
    console.log('WORKER');
    //debugger;
    switch (action.partialStateName) {
        case "loadpriv":
            try {
                const payload = yield call(loadPrivData, action);
                yield put({ type: ANNOUNCEMENT_PRIVLIST_STATE_LOADED, "loadpriv": payload });
            } catch (e) {
                yield put({ type: ANNOUNCEMENT_PRIVLIST_STATE_LOAD_ERROR, payload: e });
            }
            break;
        case "loaddata":
            try {
                const payload = yield call(loadData, action);
                yield put({ type: ANNOUNCEMENT_GRID_STATE_LOADED, "loaddata": payload });
            } catch (e) {
                yield put({ type: ANNOUNCEMENT_GRID_STATE_LOAD_ERROR, payload: e });
            }
            break;
        case "loadtypes":
            try {
                console.log('WORKER: Load Types');
                const payload = yield call(loadTypes, action);
                yield put({ type: ANNOUNCEMENT_TYPE_STATE_LOADED, "loadtypes": payload });
            } catch (e) {
                yield put({ type: ANNOUNCEMENT_TYPE_STATE_LOAD_ERROR, payload: e });
            }
            break;
        case "update":
            try {
                //console.log('WORKER: Update - Call');
                const payload = yield call(updateData, action);
                //console.log('WORKER: Update - Put');
                yield put({ type: ANNOUNCEMENT_GRID_STATE_UPDATED, "update": payload });
                //console.log('WORKER: Yield ANNOUNCEMENT_GRID_STATE_UPDATED');
            } catch (e) {
                yield put({ type: ANNOUNCEMENT_GRID_STATE_UPDATE_ERROR, payload: e });
            }
            break;
        case "remove":
            try {
                const payload = yield call(removeData, action);
                yield put({ type: ANNOUNCEMENT_GRID_STATE_REMOVED, "remove": payload });
            } catch (e) {
                yield put({ type: ANNOUNCEMENT_GRID_STATE_REMOVE_ERROR, payload: e });
            }
            break;
        case "insert":
            try {
                const payload = yield call(insertData, action);
                yield put({ type: ANNOUNCEMENT_GRID_STATE_INSERTED, "insert": payload });
            } catch (e) {
                yield put({ type: ANNOUNCEMENT_GRID_STATE_INSERT_ERROR, payload: e });
            }
            break;
    }
}

function removeData(action) {
    return sendRequest(`${url}/Delete`, 'DELETE', action.partialStateValue);
}

function insertData(action) {
    return sendRequest(`${url}/Insert`, 'POST', action.partialStateValue);
}

function loadData(action) {
    return sendRequest(`${url}/List`);
}

function loadPrivData(action) {
    return sendRequest(`${url}/ListByType?type=2`);
}

function updateData(action) {
    return sendRequest(`${url}/Update`, 'PUT', action.partialStateValue);
}

function loadTypes(action) {
    return sendRequest(`${url}/Types`);
}