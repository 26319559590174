export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    GETROLE_REQUEST: 'USERS_GETROLE_REQUEST',
    GETROLE_SUCCESS: 'USERS_GETROLE_SUCCESS',
    GETROLE_FAILURE: 'USERS_GETROLE_FAILURE',

    GETPROFILE_REQUEST: 'USERS_GETPROFILE_REQUEST',
    GETPROFILE_SUCCESS: 'USERS_GETPROFILE_SUCCESS',
    GETPROFILE_FAILURE: 'USERS_GETPROFILE_FAILURE',
    
    LOGOUT: 'USERS_LOGOUT',
    LOGOUT_SUCCESS: 'USERS_LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'USERS_LOGOUT_FAILURE',

    //RETRIEVEROLE_REQUEST: 'USERS_RETRIEVEROLE_REQUEST',
    //RETRIEVEROLE_SUCCESS: 'USERS_RETRIEVEROLE_SUCCESS',
    //RETRIEVEROLE_FAILURE: 'USERS_RETRIEVEROLE_FAILURE',

    //GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    //GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    //GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    USERS_GETABILITY_REQUEST: 'USERS_GETABILITY_REQUEST',
    USERS_GETABILITY_SUCCESS: 'USERS_GETABILITY_SUCCESS',
    USERS_GETABILITY_FAILURE: 'USERS_GETABILITY_FAILURE',

    CHECKIN_INSERT: 'CHECKIN_INSERT',
    CHECKIN_INSERTED: 'CHECKIN_INSERTED',
    CHECKIN_FAILURE: 'CHECKIN_FAILURE',

};
