import { userConstants } from '../_constants';
//import { userService } from '../_services';
//import { alertActions } from './alert.actions';
//import { history } from '../_helpers';

export const userActions = {
    //login,
    //logout,
    //getAll,
    //getUserRole,
    getUserAbility,
    getUserProfile,
    checkIn
};
/*
function login(username, password) {
    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then(
                user => { 
                    dispatch(success(user));
                    //dispatch(alertActions.success());
                    //history.push('/home');
                },
                error => {
                    //debugger;
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}


function getuserrole(employeeid) {
    return dispatch => {
        dispatch(request({ employeeid }));

        userService.getuserrole(employeeid)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function getAll() {
    return dispatch => {
        dispatch(request());

        userService.getAll()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

function login(username, password) {
    //debugger;
    return {
        type: userConstants.LOGIN_REQUEST,
        username: username,
        password: password
    };
}
function logout() {
    return { type: userConstants.LOGOUT }
}
function getAll() {
    return { type: userConstants.GETALL_REQUEST }
}
*/
//function getUserRole(employeeid) {
    
//    return {
//        type: userConstants.GETROLE_REQUEST,
//        employeeid: employeeid
//    };
//}

function getUserAbility(userName) {
    return {
        type: userConstants.USERS_GETABILITY_REQUEST,
        userName: userName

    };
}

function getUserProfile(userName) {
    //debugger;
    return {
        type: userConstants.GETPROFILE_REQUEST,
        userName: userName
    };
}

function checkIn(partialStateName, partialStateValue) {
    return {
        type: userConstants.CHECKIN_INSERT,
        partialStateName,
        partialStateValue
    };
}



