import React from 'react';
import ContextMenu from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import './user-panel.scss';

//import AdalConfig from '../../config/AdalConfig';
import AuthContext from '../../services/Auth';
import { withRouter } from 'react-router-dom';
import { connect, Provider } from "react-redux";
import { store } from '../../_helpers';
import pic from '../../demo-data/pic_placeholder.svg';
import defineAbilityFor from '../../config/abilityrule';

class UserPanel extends React.Component {
    constructor(props) {
        super(props);

        this.onInit = this.onInit.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    componentDidMount() {
        console.log(this.props);
    }

    render() {
        const { menuMode, menuItems } = this.props;


        //AuthContext.acquireToken(AdalConfig.endpoints.api, (message, token, msg) => {
        //    if (token) {
        //        localStorage.setItem('adal.idtoken', token);
        //    }
        //});

        //const user = AuthContext.getCachedUser();
        const profile = store.getState().userrole.profile;
        const profilepic = profile ? `data:image/jpeg;base64,` + profile[0].Image : pic;
        const userName = profile ? profile[0].FirstName + ' ' + profile[0].LastName : 'User Name';
        //debugger;
        return (
            <div className={'user-panel'}>
                <div className={'user-info'} >
                    <div className={'image-container'}>
                        {/*<div className={'user-image'} />*/}
                        <img src={profilepic} className={'image-details'} />
                    </div>
                    <div className={'user-name'}>{userName}</div>
                </div>

                {menuMode === 'context' && (
                    <ContextMenu
                        items={menuItems}
                        target={'.user-button'}
                        showEvent={'dxclick'}
                        position={{ my: 'top center', at: 'bottom center' }}
                        cssClass={'user-menu'}
                        onInitialized={this.onInit}
                        onItemClick={this.onClick}
                    />
                )}
                {menuMode === 'list' && (
                    <List
                        className={'dx-toolbar-menu-action'}
                        items={menuItems}
                    />
                )}
            </div>
        );
    }

    onInit(args) {
        //debugger;
        //2019-12-23 Apply Side Navigation Permission 
        var ability = defineAbilityFor(store.getState().userrole.userAbility);
        var ManageAnnouncementVisibility = ability.can('Announcement Manage - View', '') ? true : false;
        // Manipulate Navigation Array
        var objIndex = args.component._userOptions.items.findIndex((obj => obj.text == 'Manage Announcement'));
        args.component._userOptions.items[objIndex].visible = ManageAnnouncementVisibility;
    }

    onClick(args){
        //debugger;
        const path = args.itemData.path;
        const click = args.onClick;

        if (path && !click) {
            this.props.history.push(path);
        }
    }
    
}

function mapStateToProps(state) {
    return {
        state: state.userrole
    };
}

const connectedUserPanel = connect(mapStateToProps)(UserPanel);

export default withRouter(connectedUserPanel)
