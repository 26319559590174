import React from 'react';
import TabPanel from 'devextreme-react/tab-panel';
import { Item } from 'devextreme-react/list';
import { ScrollView } from 'devextreme-react';
import './mapfiltercontent.scss';
import FilterData from './filterdata.js'

class FilterContent extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state =
            {
            selectedIndex: 1,
            };

        this.onSelectionChanged = this.onSelectionChanged.bind(this);

        //debugger;
        //this.props.changeText(); 
    }

    componentDidMount() {
        this.setState({ selectedIndex:0 });
    }

    onSelectionChanged(args) {

        if (args.name == 'selectedIndex') {
            this.setState({
                selectedIndex: args.value
            });
        }

       
    }

    render() {
        const { selectedIndex } = this.state;

        return (
            <React.Fragment>
                <div className={'panel-list'} >
                    <TabPanel
                        height={'100%'}
                        selectedIndex={selectedIndex}
                        onOptionChanged={this.onSelectionChanged}
                    >
                        <Item title={"Filter By"}>
                            <FilterData updateMap={this.props.updateMap} closeFilter={this.props.closeFilter}/>
                        </Item>
                        {/*
                        <Item title={"Team"}>
                            <div>Coming Soon</div>
                        </Item> */}
                    </TabPanel>
                </div>
            </React.Fragment>
        );
    }
}

export default FilterContent;