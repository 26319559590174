import {
    CHECKINS_GRID_STATE_LOAD,
    CHECKINS_GRID_STATE_LOADED,
    CHECKINS_GRID_STATE_LOAD_ERROR
} from "../_constants/action-types"

const gridWInitialState = {
    checkinsData: [],
    completed: false,
    loadcheckinsdata: []
};

function checkinsGridWReducer(state = gridWInitialState, action) {
    // LOAD
    if (action.type === CHECKINS_GRID_STATE_LOAD) {
        return {
            ...state,
            [action.partialStateName]: action.partialStateValue,
        };
    }
    if (action.type === CHECKINS_GRID_STATE_LOADED) {
        //debugger;
        return Object.assign({}, state, {
            loadcheckinsdata: action.loadcheckinsdata,
            completed: true,
            succeeded: true
        });
    }
    if (action.type === CHECKINS_GRID_STATE_LOAD_ERROR) {
        return Object.assign({}, state, {
            [action.partialStateName]: action.partialStateValue,
            completed: true,
            succeeded: false,
            apiError: true,
            message: 'Operation failed!'
        });
    }
    return state;
}

export default checkinsGridWReducer;