import React, { useState, Component } from "react";
import { compose, withProps } from "recompose"
import { GoogleMap, Marker, InfoWindow, TrafficLayer } from "react-google-maps";
import {
    withGoogleMap,
    withScriptjs
} from "react-google-maps";
import './map.scss';

require('dotenv').config({ debug: false })
const url = process.env.REACT_APP_MAP_API_URL + '/api/map/EmployeeList';
const key = process.env.REACT_APP_GOOGLE_KEY;


const MapWithMarker = compose(
    withScriptjs,
    withGoogleMap)(props => {

    return (
        <GoogleMap
            defaultZoom={13}
            defaultCenter={{ lat: 34.0664985, lng: -117.2890371 }}
        >
            <TrafficLayer autoUpdate />
            <Marker
                key={1}
                position={{
                    lat: 34.0664985,
                    lng: -117.2890371
                }}
                onClick={props.onToggleOpen}
                icon={{
                    url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
                    ,
                    scaledSize: new window.google.maps.Size(30, 30)
                }}
            >
            {
                  props.isOpen && <InfoWindow
                    onCloseClick={props.onToggleOpen}
                    position={{
                        lat: 34.0664985,
                        lng: -117.2890371
                    }}

                >
                    <div>
                        <div style={{ fontWeight: 'bold' }}>ATC Headquarter</div>
                        <div>268 W Hospitality Ln</div>
                            <div>San Bernardino,CA 92415</div>
                            <div>(909)387-8322</div>
                    </div>
                </InfoWindow>
                
                }
            </Marker>
            {
                props.markers.map(marker => {
                    //debugger;
                    let visible = false;
                    let viewtype = props.viewtype[0];
                    let division = props.division;

                    if (viewtype == 1) {
                        if (division.includes(marker.Division) || division.length==0) {
                            visible = true;
                        }
                       
                    }
                    else if (viewtype == 2 && marker.CheckedIn ==true) {
                        if (division.includes(marker.Division) || division.length == 0) {
                            visible = true;
                        }
                    }

                    


                        return (
                            <Marker
                                key={marker.Id}
                                position={{
                                    lat: marker.Lat,
                                    lng: marker.Lng
                                }}
                                onClick={() => props.onClick(marker)}
                                icon={{
                                    url: 'http://maps.google.com/mapfiles/ms/icons/' + (marker.CheckedIn==true?'red':'yellow') + '-dot.png'
                                    ,
                                    scaledSize: new window.google.maps.Size(25, 25)
                                }}
                                visible={visible}
                            >
                                {
                                    props.selectedMarker === marker &&
                                    <InfoWindow
                                        onCloseClick={() => props.onClick(null)}
                                        position={{
                                            lat: marker.Lat,
                                            lng: marker.Lng
                                        }}
                                    >
                                        <div>
                                            <div >
                                                <div style={{ fontWeight: 'bold', float: 'left' }}>{marker.FirstName} {marker.LastName}</div>
                                                <div style={{ float: 'right' }}>
                                                    <span className={marker.CheckedIn == true ? 'online' :''}></span>
                                                   </div>
                                            </div>
                                            <div style={{clear:'left'}} >{marker.Street}</div>
                                            <div>{marker.City},{marker.State} {marker.Zipcode}</div>
                                            <div>{marker.WorkPhoneNumber}</div>
                                        </div>
                                    </InfoWindow>
                                }

                            </Marker>
                        )
                    
                    })
            }
         
        </GoogleMap>
    );
})


export default class ReactGoogleMaps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            markers: [],
            selectedMarker: false,
        }

    }

    componentDidMount() {
            fetch(url)
                .then(r => r.json())
                .then(data => {
                    this.setState({ markers: data })
                })
    }

    onToggleOpen = () => {
        //debugger;
        this.setState({ isOpen: !this.state.isOpen });
    }

    handleClick = (marker, event) => {
        //debugger;
        this.setState({ selectedMarker: marker });
    }

    render() {


        return (

            <MapWithMarker
                onToggleOpen={this.onToggleOpen}
                isOpen={this.state.isOpen}
                selectedMarker={this.state.selectedMarker}
                markers={this.state.markers}
                onClick={this.handleClick}
                viewtype={this.props.viewtype}
                division={this.props.division}

                googleMapURL={'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=' + key}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100vh` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                />
           
        );
    }
};


