import "./help.scss";

import React, { Component } from "react";
import ReactDOM from 'react-dom';
import List, { Item } from 'devextreme-react/list';
import TabPanel from 'devextreme-react/tab-panel';
import { CSSTransition } from "react-transition-group";
import { ScrollView } from 'devextreme-react';
import defineAbilityFor from '../../config/abilityrule';
import { store } from '../../_helpers';
import HelpDeskForm from './helpdeskform.js';
import Help01 from './help01.js';

class help extends React.PureComponent {
    constructor(props) {
        super(props);
        const ability = defineAbilityFor(store.getState().userrole.userAbility);
        this.state =
        {
            selectedIndex: 0,
            loop: false,
            swipeEnabled: true,
            animationEnabled: true,
            ability: ability,
            scrollByContent: true,
            scrollingEnabled: true,
            showNavButtons:false
        };
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
    }
    onSelectionChanged(args) {
        if (args.name == 'selectedIndex') {
            this.setState({
                selectedIndex: args.value
            });
        }
    }
    componentDidMount() { }
    componentWillMount() { }
    componentWillUpdate() { }

    changeTabIndex = (index) => {
        this.setState({ selectedIndex: index });
    }

    render() {
        const { selectedIndex, ability, swipeEnabled, animationEnabled, loop, scrollByContent, scrollingEnabled, showNavButtons} = this.state;

        return (
            <React.Fragment>
                <div className={'content-block'}>
                    <div className={'dx-card responsive-paddings'}>
                        <div className={'title-padding'}>
                            <span className={'pagetitle'}>Help Information</span>
                        </div>

                        <TabPanel className="tab-panel"
                            selectedIndex={selectedIndex}
                            onOptionChanged={this.onSelectionChanged}
                            loop={loop}
                            animationEnabled={animationEnabled}
                            swipeEnabled={swipeEnabled}
                            scrollByContent={scrollByContent}
                            scrollingEnabled={scrollingEnabled} showNavButtons={showNavButtons}
                        >
                            
                            <Item title={"General"} >
                                <ScrollView >
                                    <Help01 changeTabIndex={this.changeTabIndex}/>
                                </ScrollView>
                            </Item>
                            <Item title={"Submit Help Ticket"}  >
                                <ScrollView >
                                    <HelpDeskForm/>
                                </ScrollView>
                            </Item>

                        </TabPanel>

                    </div>
                </div>
            </React.Fragment>
        );
    }

}
export default help;