import React from 'react';
import './broadcast.scss';
import Button from 'devextreme-react/button';
import List, { Item } from 'devextreme-react/list';
import TabPanel from 'devextreme-react/tab-panel';
import broadcastsms from './broadcastbysms.js';
import broadcastvideo from './broadcastbyvideo.js';


class broadcast extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = { selectedIndex: 0 }
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
    }

    onSelectionChanged(args) {
        if (args.name == 'selectedIndex') {
            this.setState({
                selectedIndex: args.value
            });
        }
    }

    //}
    //export default () => (
    render() {
        const { selectedIndex } = this.state;
        return (

            <React.Fragment>
                {/*<h2 className={'content-block'}>Broadcast Message</h2> */}
                <div className={'content-block'}>
                    <div className={'dx-card responsive-paddings'}>

                        <div className={'title-padding'}>
                            <span className={'pagetitle'}>Manage Announcement</span>
                        </div>

                        
                            <TabPanel
                                height={600}
                                //dataSource={companies}
                                selectedIndex={selectedIndex}
                                onOptionChanged={this.onSelectionChanged}
                            //loop={loop}
                            // itemTitleRender=  //{this.itemTitleRender}
                            // itemComponent={CompanyItem}
                            //animationEnabled={animationEnabled}
                            //swipeEnabled={swipeEnabled}
                            >
                                <Item title={"Send By Text"} component={broadcastsms} />
                                <Item title={"Send By Voice"} component={broadcastvideo} />

                            </TabPanel>

                        
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default broadcast;