import "./directory.scss";
import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { TabPanel, ScrollView } from "devextreme-react";
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { Button } from "devextreme-react/button";
import { TextBox } from 'devextreme-react';
import { Item } from "devextreme-react/lookup";
import employees_by_floor from "../../demo-data//employee-data.js";
import SmallField from "../../components/smallfield/SmallField";
import Employee from "./employee";
import Accordion from "../../components/accordion/Accordion";
import { CSSTransition } from "react-transition-group";
import pic from '../../demo-data/pic_placeholder.svg';
import List from '@researchgate/react-intersection-list';
import ContentLoader, { Facebook, Instagram } from 'react-content-loader'
import defineAbilityFor from '../../config/abilityrule';
import { store } from '../../_helpers';

require('dotenv').config({ debug: false })
const url = process.env.REACT_APP_DIRECTORY_API_URL + "/api/Directory";
const PAGE_SIZE = 6;
const MyLoader = () => <ContentLoader />
const MyFacebookLoader = () => <Facebook />
const MyInstagramLoader = () => <Instagram />

const cellRenderPhone = (data) => <div><i class="fas fa-phone-alt phone"></i> <a href={`tel:${data.value}`} className={'phonelink'}>{data.value}</a></div>;

class Directory extends Component {
    constructor(props) {
        super(props);
        //this.employees_floors = employees_by_floor;
        //this.employeeData = [];
        this.state = {
            //employeeData: [],
            //currentFloor: [],
            //currentFloor: this.employees_floors[0],
            currentEmployee: [],
            //currentEmployee: this.employees_floors[0].employees[0],
            rightContentMobileClassName: "",
            leftContentMobileClassName: "",
            awaitMore: true,
            isLoading: false,
            currentPage: -1,
            employees: [],
            searchText: '',
            timeoutID: null
        };
        this.updateCurrentEmployee = this.updateCurrentEmployee.bind(this);
        this.searchTextChanged = this.searchTextChanged.bind(this);
    }

    feedList(employees) {
        //debugger;
        console.log('feedlist is called at employees array size ' + employees.length);
        this.setState({
            awaitMore: employees.length > 0,
            isLoading: false,
            employees: [...this.state.employees, ...employees],
            currentEmployee: this.state.employees.length == 0 ? employees[0] : this.state.currentEmployee
        });

        if (employees.length >0 )
            //20191003 - <List> component doesnt re-render when size is unchanged; as work-around, use session-storage to pass currentEmployee to <Employee> component.
            sessionStorage.setItem('atcer_currentemployee', this.state.currentEmployee.employeeID);
        //debugger;
        console.log('feedlist total employees array size ' + this.state.employees.length + ', awaitMore: ' + this.state.awaitMore);
    }

    handleLoadMore = () => {
        
        const currentPage = this.state.currentPage + 1;
        console.log('>handleLoadMore is called at pg ' + currentPage);

        this.setState({
            isLoading: true,
            currentPage,
        });

        //const source_url = url + "/ListEmployeesByPage";
        //const qs = `?pagesize=${PAGE_SIZE}&currentpage=${currentPage}`;

        const source_url = url + "/ListEmployeesBySearch";
        const qs = `?textSearch=${this.state.searchText}&pagesize=${PAGE_SIZE}&currentpage=${currentPage}`;

        const headers = {
            'Accept-Encoding': '',
        };
        const ifNoneMatch = sessionStorage.getItem(`etag_${currentPage}`);
        if (ifNoneMatch) {
            headers['If-None-Match'] = ifNoneMatch.match(/(?:\d|[a-z])+/)[0];
        }

        let hasError = false;
        console.log('>>start fecthing');
        fetch(source_url + qs, { headers })
            .then(response => {
                if (!(response.status !== 200)) {
                    console.log('>>get etag');
                    const etag = response.headers.get('etag');
                    if (etag) {
                        sessionStorage.setItem(`etag_${currentPage}`, etag);
                    }
                } else {
                    hasError = true;
                }
                return response.json();
            })
            .then(employees => {
                if (hasError) {
                    throw new Error(employees.message);
                }
                console.log('>>call feedlist');
                this.feedList(employees);
            })
            .catch(err => {
                console.error(err); // eslint-disable-line
                this.feedList([]);
            });


    } // handleLoadMore

    
    componentDidMount() {
        /*fetch(url+"/List")
          .then(response => response.json())
          .then(employees => this.setState({ employeeData: employees, currentEmployee:employees[0] }));
        */
    }

    componentWillMount() {
        /*
        const source_url = url + "/ListOfEmployee?pagesize=10&currentpage=0";

        fetch(source_url)
            .then(response => response.json())
            .then(employees => this.setState({
                employeeData: employees,
                //currentFloor: employees[0], 
                currentEmployee: employees[0]
            }))
            .then(console.log(this.currentEmployee))
            .catch(() => console.log("Can't access " + source_url + " response."))
        */
    }

    componentWillUpdate(nextProps, nextState) {
        // this area will execute when there are state updates
        //debugger;
        //console.log('componentwillupdate - updated:' + nextProps.state.currentEmployee);
    }

    renderItems = (items, ref) => (
        
            <div className="employee-list-content" ref={ref}>
                {items.length > 0 ? items : 
                            <div className={"employee"}>
                                <div className={"noresult"}>
                                    Sorry, empty result. Please try again.
                                </div>
                            </div>
                }
            
            </div>
            
    );

    renderItem = (index, key) => {
        //debugger;
        const employee = this.state.employees[index];
        const currentEmployee = this.state.currentEmployee;
        return (
            
            <div key={key} id={key}>
                <Employee
                    key={employee.employeeID}
                    passClick={this.updateCurrentEmployee}
                    employee={employee}
                    currentEmployee={currentEmployee}
                    className={`${
                        //employee.employee_id === currentEmployee.employee_id
                        employee.employeeID === this.state.currentEmployee.employeeID
                            ? "employee-current"
                            : ""
                        } employee`}
                />
                
            </div>
        );
    };

    searchTextChanged(data) {
        
        console.log('Search Text: ' + data.value);

        if (this.state.timeoutID) {
            window.clearTimeout(this.state.timeoutID);
            this.state.timeoutID = null;
        }
        this.state.timeoutID = setTimeout(() => {
            console.log('Search Text After Timeout: ' + data.value);
            this.setState({
                searchText: `${data.value.replace(/\s/g, '').toLowerCase()}`,
                employees: [],
                currentPage: -1,
                awaitMore: true,
                isLoading: false,
            });
        }, 500);  
    }

    render() {
        const {
            //employeeData,
            //currentFloor,
            currentEmployee,
            rightContentMobileClassName,
            leftContentMobileClassName
        } = this.state;
        var ability = defineAbilityFor(store.getState().userrole.userAbility);
        return (
            <div className="directory-container">
                <div className="directory-header">
                    <div className={"title"}>
                        <span className={"pagetitle"}>Directory</span>
                    </div>
                    <div className={"reload"}>
                        <Button
                            className={"reload-button"}
                            icon={"pulldown"} s
                            stylingMode={"text"}
                            text={"Refresh"}
                            onClick={() => {
                                this.setState({
                                    employees: [],
                                    currentPage: -1,
                                    awaitMore: true,
                                    isLoading: false, }) }}
                        />
                    </div>
                </div>
                <div className="directory-content">
                    <div className={`left-content ${leftContentMobileClassName}`}>
                        <div className="employee-list-header">
                            Employee List
                            <TextBox
                                showClearButton={true}
                                placeholder={'Type Search Text Here'}
                                valueChangeEvent={'keyup'}
                                onValueChanged={this.searchTextChanged}
                                width={'100%'}
                                className={'searchTextBox'}
                                />
                            <div className="panelLoading">
                                {this.state.isLoading && <div className="loading"><div className="inprogress"></div>Loading...</div>}
                            </div>
                        </div>
                        <div className="employee-list-content">
                            {this.state.employees.length==0 && <div className={'contentHolderSmall'}><MyFacebookLoader /></div>}
                            <List
                                awaitMore={this.state.awaitMore}
                                ref={(ref) => this.list = ref}
                                itemsRenderer={this.renderItems}
                                itemCount={this.state.employees.length}
                                onIntersection={this.handleLoadMore}
                                pageSize={PAGE_SIZE}
                                renderItem={this.renderItem}
                            />
                        
                        </div>
                    </div>
                    {/* end left-content */}
                    <CSSTransition
                        in={`${rightContentMobileClassName}`}
                        timeout={1000}
                        classNames={"fade"}
                    >
                        <div className={`right-content ${rightContentMobileClassName}`}>
                            <button
                                className="close-button"
                                onClick={this.closeDetailWindow.bind(this)}
                            >
                                &#10006;
                            </button>
                            
                            <TabPanel className="employee-tab">
                                <Item title="Profile">
                                    {this.state.isLoading ? <div className={'contentHolder'}><MyFacebookLoader /></div> :
                                    currentEmployee && <div className="tab">
                                        {/* ABOUT section */}
                                        <div className="subtitle">About</div>
                                        <hr />
                                        <div className="about-section">
                                            {/*<img src={"." + require(`${currentEmployee.img}`)} alt="image" />*/}
                                            <div className="employeeImageDetails-wrapper">
                                                <img
                                                    //src={currentEmployee.img}
                                                    src={`data:image/jpeg;base64,${currentEmployee.img}`}
                                                    alt="image"
                                                    className="employeeImageDetails"
                                                />
                                            </div>
                                            <SmallField
                                                className="employee-id"
                                                title="employee ID"
                                                //content={currentEmployee.employee_id}
                                                content={currentEmployee.employeeID}
                                            />
                                            <SmallField
                                                className="firstname"
                                                title="firstname"
                                                content={currentEmployee.firstName}
                                            />
                                            {/*<SmallField
                                                className="middlename"
                                                title="middlename"
                                                content={currentEmployee.middleName}
                                            />*/}
                                            <SmallField
                                                className="lastname"
                                                title="lastname"
                                                content={currentEmployee.lastName}
                                            />
                                            <SmallField
                                                className="alias"
                                                title="alias"
                                                content={currentEmployee.alias}
                                            />
                                            <SmallField
                                                className="gender"
                                                title="gender"
                                                content={currentEmployee.gender}
                                            />
                                            {/*<SmallField
                                                className="race"
                                                title="race"
                                                content={currentEmployee.race}
                                            />
                                            <SmallField
                                                className="has-auto-insurance"
                                                title="has auto insurance"
                                                content={currentEmployee.has_auto_insurance}
                                            />*/}
                                            <SmallField
                                                className="employment"
                                                title="employment"
                                                content={currentEmployee.employment}
                                            />
                                        </div>

                                        {/* ADDRESS & PHONE section */}
                                            {ability.can('Directory - View Address-Personal Phone', '') ?
                                                <div>
                                                <div className="subtitle">Address & Phone</div>
                                                <hr />
                                                <div className="address-phone-section">
                                                    <SmallField
                                                        className="address"
                                                        title="address"
                                                        content={currentEmployee.address}
                                                    />
                                                    <SmallField
                                                        className="city"
                                                        title="city"
                                                        content={currentEmployee.city}
                                                    />
                                                    <SmallField
                                                        className="state"
                                                        title="state"
                                                        content={currentEmployee.state}
                                                    />
                                                    <SmallField
                                                        className="zipcode"
                                                        title="zipcode"
                                                        content={currentEmployee.zipcode}
                                                    />
                                                    <SmallField
                                                        className="homephone"
                                                        title="homephone"
                                                        //content={currentEmployee.personal_phone}
                                                        content={currentEmployee.homePhoneNumber || currentEmployee.cellPhoneNumber}
                                                        fieldType={'tel'}
                                                    />
                                                </div></div>
                                                : ""
                                        }
                                        {/* DEPARTMENT section */}
                                        <div className="subtitle">Department</div>
                                        <hr />
                                        <div className="department-section">
                                            <SmallField
                                                className="department"
                                                title="department"
                                                content={currentEmployee.division}
                                            />
                                            <SmallField
                                                className="employee-title"
                                                title="title"
                                                //content={currentEmployee.title}
                                                content={currentEmployee.jobTitle}
                                            />
                                            <SmallField
                                                className="floor"
                                                title="floor"
                                                content={currentEmployee.floor}
                                            //content={currentFloor.floorName}
                                            />
                                            <SmallField
                                                className="workphone"
                                                title="workphone"
                                                    content={currentEmployee.phone}
                                                    fieldType={'tel'}
                                            />
                                            <SmallField
                                                className="email"
                                                title="email"
                                                    content={currentEmployee.email}
                                                    fieldType={'mailto'}
                                            />
                                        </div>
                                        </div>
                                    }
                                </Item>
                                {ability.can('Directory - View Emergency Contacts', '') ?
                                    <Item
                                        title="Emergency Contact"
                                        className="employee-tab-emergencycontact"
                                    >
                                        {currentEmployee &&
                                            <DataGrid
                                                dataSource={currentEmployee.emergency_contact}>
                                                <Column dataField={'relationship'} />
                                                <Column dataField={'firstName'} />
                                                <Column dataField={'lastName'} />
                                                <Column dataField={'phoneNumber'}
                                                    cellRender={cellRenderPhone} />
                                                <Column dataField={'phoneType'} />
                                            </DataGrid>}
                                    </Item>
                                    :""
                                    }
                            </TabPanel>
                        </div>
                        {/* end right-content */}
                    </CSSTransition>
                </div>
                {/* end directory-content */}
            </div> // end directory-container
        );
    }


    updateCurrentEmployee = (
        newEmployee,
        newRightContentMobileClassName,
        newLeftContentMobileClassName
    ) => {
        //debugger;
        //ReactDOM.unstable_batchedUpdates(() => {
        this.setState({
            currentEmployee: newEmployee,
            rightContentMobileClassName: newRightContentMobileClassName,
            leftContentMobileClassName: newLeftContentMobileClassName
        });
        
        //});
            
    };


    closeDetailWindow = () => {
        this.setState({
            rightContentMobileClassName: "",
            leftContentMobileClassName: ""
        });
    };
}

export default Directory;
