import React, { Component } from "react";
import pic from '../../demo-data/pic_placeholder.svg';
import EllipsisText from "react-ellipsis-text";

class Employee extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currEmployee: []
        }
    }

    componentWillUpdate(nextProps, nextState) {
        // this area will execute when there are state updates
        //debugger;
    }
    updateCurrentEmployee = () => {
        this.props.passClick(this.props.employee, "mobile-visible", "mobile-invisible");
        //debugger;
        //20191003 - Read selected employee from session-storage.
        var prevSel = sessionStorage.getItem('atcer_currentemployee');
        if (prevSel) {
            // Use js getElementId, since <List> doesnt re-render
            var elemprev = document.getElementById(prevSel);
            // Remove selected background-color
            elemprev.className = "employee";
            //debugger;
        }
        //20191003 - Change selected employee background-color
        var elem = document.getElementById(this.props.employee.employeeID);
        elem.className = "employee-current employee "
        // Store new selected employee to session
        sessionStorage.setItem('atcer_currentemployee', this.props.employee.employeeID);
    }

    render() {
        
        return (
            <div key={this.props.key} id={this.props.employee.employeeID}
            className={`${this.props.currentEmployee && this.props.employee.employeeID === this.props.currentEmployee.employeeID
                ? "employee-current"
                : ""} employee`}
                onClick={this.updateCurrentEmployee.bind(this.props.currentEmployee)}>
            <div className={'employeeImageDetails-wrapper'}>
                <img src={`data:image/jpeg;base64,${this.props.employee.img}`} alt="image" className={'employeeImageDetails'} />
            </div>
            <div className="employee-information">
                <div className="name">
                    {this.props.employee.firstName} {this.props.employee.lastName}
                </div>
                <p>
                    {this.props.employee.jobTitle} - {this.props.employee.division}
                </p>
                    
                    <p><i class="fas fa-phone-alt phone"></i><a href={`tel:${this.props.employee.phone}`} className={'phonelink'}>{this.props.employee.phone}</a></p>
                        
                    <p><i class="far fa-envelope email"></i>
                        <a href={`mailto:${this.props.employee.email}`} className={'emaillink'}>
                        <EllipsisText
                            text={this.props.employee.email ? this.props.employee.email : ""}
                            length={25} />
                        </a>
                </p>
            </div>
        </div>
        )
    }
}


export default Employee;
