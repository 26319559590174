import React from 'react';
import { DataGrid, Column, Editing, Scrolling, Lookup, Grouping, Pager, Paging, Sorting, RequiredRule, Position, Form, Popup  } from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import { Item } from 'devextreme-react/form';
import Button from 'devextreme-react/button';
import CustomStore from 'devextreme/data/custom_store';
import { fetchData, loadData, loadTypes, updateData, insertData, removeData, getTypes } from "../../_actions/announcement.actions";
import { createGridWAction } from "../../_actions/announcement.actions";
import { connect, Provider } from "react-redux";
//import store from "../../_store/announcement-grid-store"
import { store } from '../../_helpers';
import './announcement.scss';
//import { ABILITIES } from '../../config/ability';
import defineAbilityFor from '../../config/abilityrule';
import AuthContext from '../../services/Auth';

//const URL = 'http://localhost:4633/api/v1/Announcement';
//const URL = 'http://atc-nash-dev/atceapi-announcement/api/v1/Announcement';
//const URL = 'https://atceapiprod-all.azurewebsites.net/api/v1/Announcement';

export class GridW extends React.Component {

    constructor(props) {
        super(props);


        //const userRole = store.getState().userrole.role;
        const ability = defineAbilityFor(store.getState().userrole.userAbility);

        //debugger;

        this.state = {
            announcementsData: new CustomStore({
                key: 'Id',
                load: this.loadData,
                insert: this.insertData,
                update: this.updateByKey,
                remove: this.deleteByKey,
            }),
            annoucementTypesData: new CustomStore({
                key: 'Value',
                loadMode: 'raw',
                load: this.loadTypes
            }),
            requests: [],
            events:[],
            refreshMode: 'reshape',
            updated: false,
            loaddata: [],
            loadtypes: [],
            ability: ability//userRole ? ABILITIES[userRole] : ABILITIES.Staffs
        };
        
        var handleToUpdate = this.handleToUpdate.bind(this);
        this.handleOptionChanged = this.handleOptionChanged.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);

       // debugger;
    } //constructor

    loadData(values) {
        var response = store.dispatch(loadData('loaddata', values));
        console.log(`Load Data`);
        return new Promise((resolve, reject) => {
            const unsubscribe = store.subscribe(() => {
                const { completed, succeeded, loaddata, ...extraParams } = store.getState().gridWReducer;

                if (!completed) return;

                unsubscribe();
                if (succeeded) {
                    resolve(loaddata);
                } else {
                    reject(extraParams.message);
                }
            });
        });
    } // loadData

    insertData(values) {

        //values.userName = AuthContext.getCachedUser().userName;
        //values.profileName = AuthContext.getCachedUser().profile.name;
        const profile = store.getState().userrole.profile;
        if (profile) {
            values.EmployeeId = profile[0].EmployeeId;
            values.UserName = profile[0].FirstName + ' ' + profile[0].LastName;
        }

        store.dispatch(insertData('insert', {
            values: JSON.stringify(values)
        }));
        console.log(`Insert New Record`);

        return new Promise((resolve, reject) => {
            const unsubscribe = store.subscribe(() => {
                const { completed, succeeded, insert, ...extraParams } = store.getState().gridWReducer;

                if (!completed) return;

                unsubscribe();

                if (succeeded) {
                    resolve(insert);
                } else {
                    reject(extraParams.message);
                }
            });
        });
    }// insertData

    updateByKey(key, values) {

        const profile = store.getState().userrole.profile;
        if (profile) {
            values.UserName = profile[0].FirstName + ' ' + profile[0].LastName;
        }

        store.dispatch(updateData('update', {
            key: key,
            values: JSON.stringify(values)
        }));
        console.log(`Updating #${key}`);

        return new Promise((resolve, reject) => {
            const unsubscribe = store.subscribe(() => {
                const { completed, succeeded, ...extraParams } = store.getState().gridWReducer;

                if (!completed) return;

                unsubscribe();

                if (succeeded) {
                    resolve();
                } else {
                    reject(extraParams.message);
                }
            });
        });
    } // updateByKey

    deleteByKey(key) {
        store.dispatch(removeData('remove', { key: key }));
        console.log(`Deleting #${key}`);
        return new Promise((resolve, reject) => {
            const unsubscribe = store.subscribe(() => {
                const { completed, succeeded, ...extraParams } = store.getState().gridWReducer;

                if (!completed) return;

                unsubscribe();

                if (succeeded) {
                    resolve();
                } else {
                    reject(extraParams.message);
                }
            });
        });
    } // deleteByKey

    loadTypes(values) {
        var response = store.dispatch(loadTypes('loadtypes', values));
        console.log(`Load Types`);
        return new Promise((resolve, reject) => {
            const unsubscribe = store.subscribe(() => {
                const { completed, succeeded, loadtypes, ...extraParams } = store.getState().gridWReducer;

                if (!completed) return;

                unsubscribe();
                if (succeeded) {
                    resolve(loadtypes);
                } else {
                    reject(extraParams.message);
                }
            });
        });
    } // loadTypes

    componentDidMount() {};
    componentDidUpdate() {};
    componentWillUpdate(nextProps, nextState) { };
    handleStateChange(event) {}
    handleOptionChanged(event) {
        if (event.fullName.indexOf('sortOrder')>0)
            this.props._onSorting();
    }
    handleToUpdate(val) {}

    render() {
        const {
            refreshMode, announcementsData, annoucementTypesData, ability, requests, events, updated } = this.state;
        //debugger;
        var handleToUpdate = this.handleToUpdate;
        return (
            <React.Fragment>
                <DataGrid
                    id={'grid'}
                    showBorders={true}
                    dataSource={announcementsData}
                    repaintChangesOnly={true}
                    onOptionChanged={this.handleOptionChanged}
                    handleToUpdate={handleToUpdate.bind(this)}
                    wordWrapEnabled={true}
                >
                    <Editing
                        refreshMode={refreshMode}
                        mode={'form'}
                        //allowAdding={this.state.ability.can('insert', 'Announcement') ? true : false}
                        //allowUpdating={this.state.ability.can('write', 'Announcement') ? true : false}
                        //allowDeleting={this.state.ability.can('delete', 'Announcement') ? true : false}
                        allowAdding={ability.can('Announcement Manage - Create', '') ? true : false}
                        allowUpdating={ability.can('Announcement Manage - Modify', '') ? true : false}
                        allowDeleting={ability.can('Announcement Manage - Delete', '') ? true : false}
                    >
                        <Popup title={'Announcement'} showTitle={true} width={700} height={525}>
                            <Position my={'top'} at={'top'} of={window} />
                        </Popup>
                        <Form>
                            <Item itemType={'group'} colCount={2} colSpan={2}>
                                <Item dataField={'Title'} />

                                <Item dataField={'TypeId'} />
                                <Item dataField={'IsActive'} />
                                <Item dataField={'DateTimePosted'} />

                                <Item
                                    dataField={'Message'}
                                    editorType={'dxTextArea'}
                                    colSpan={2}
                                    editorOptions={{ height: 100 }}
                                />
                            </Item>
                            
                        </Form>
                    </Editing>

                    <Scrolling
                        mode={'virtual'}
                    />
                    <Sorting mode={'multiple'} />

                    {/*<Column dataField={'ID'} allowEditing={false} width={'5%'} >
                    </Column>*/}

                    <Column dataField={'Title'} editorOptions={{ maxLength: 256 }} width={'55%'} >
                        <RequiredRule />
                    </Column>

                    <Column dataField={'Message'} hidingPriority={0} caption={'Announcement Message'}>
                        <RequiredRule />
                    </Column>

                    <Column dataField={'TypeId'} caption={'Type'} width={'10%'} hidingPriority={1}>
                        <Lookup dataSource={annoucementTypesData} valueExpr={'ID'} displayExpr={'Name'} />
                        <RequiredRule />
                    </Column>

                    <Column dataField={'IsActive'} caption={'Active?'} width={'10%'} hidingPriority={1}>
                    </Column>

                    <Column dataField={'DateTimePosted'} dataType={'date'} format={'shortDateShortTime'} editorOptions={{ type: 'datetime' }} caption={'Posted'} width={'15%'} hidingPriority={2}>
                        <RequiredRule />
                    </Column>
                </DataGrid>
            </React.Fragment >
        );
    }
};

function mapDispatchToProps(dispatch) {
    return {
        _onSorting: sorting => dispatch(createGridWAction('sorting', sorting)),
    };
}
function mapStateToProps(state) {
    return {
        state: state.gridWReducer
    };
}

const Grid = connect(mapStateToProps, mapDispatchToProps)(GridW);

export default () => (
    <React.Fragment>
        {/* <h2 className={'content-block'}><span className={'pagetitle'}>Manage Announcement</span></h2>*/}
        <div className={'content-block'}>
            
            <div className={'dx-card responsive-paddings'}>
                <div className={'title-padding'}>
                    <span className={'pagetitle'}>Manage Announcement</span>
                </div>
                <Provider store={store}>
                    <Grid />
                </Provider>
                

            </div>
        </div>
    </React.Fragment>
);