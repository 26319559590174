import React from 'react';
import './Info.scss';

const InfoBoard = ({ title, content, children }) => {
    return (
        <div className="board">
            <h4 className="title">{title}</h4>
            <hr />
            {children}
        </div>
    );
}

export default InfoBoard;