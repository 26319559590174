import React, { Component } from "react";
import { render } from "react-dom";
import cheerio from "cheerio";

import "./calfire-scraper.scss";

class CalFireScraper extends Component {
    state = {
        data: []
    };

    componentDidMount() {
        this.getDataFromApi();
    }

    getDataFromApi = () => {
        const proxyurl = "https://cors-anywhere.herokuapp.com/";
        fetch(proxyurl + "https://www.fire.ca.gov/incidents/") // Mississippi
            .then(response => response.text())
            .then(data => {
                const $ = cheerio.load(data);

                var str = $("script:not([src])")[2].children[0].data;
                //onsole.log(str);

                var incidents = JSON.parse(
                    str.match(/activeIncidentData = (\[.*?\])/)[1]
                );

                //console.log(incidents);


                this.setState({ data: incidents });
            })
            .catch(error => {
                this.setState({ error: error });
            });
    };

    render() {
        const thisData = this.state.data;
        return (
            <div className="calfire">
                <h5 className='title'>List of Active Incidents</h5><hr />
                {thisData.map(data => (
                    <div key={data.IncidentId} >
                        <div className="name">
                            <a href={data.Url}>{data.Name}</a>
                        </div>

                        <span>Location: {data.Location} </span>
                        <div className="content">
                            <span className="county">Counties: {data.CountiesList}</span>
                            <span className="date">Start Date: {data.UpdatedDate} </span>
                        </div>

                    </div>
                ))}
                <div className='other'>
                    List of Incidents By Year:
              <a href="https://www.fire.ca.gov/incidents/2019">2019</a>
                    <a href="https://www.fire.ca.gov/incidents/2018">2018</a>
                    <a href="https://www.fire.ca.gov/incidents/2017">2017</a>
                </div>
            </div>
        );
    }
}

export default CalFireScraper;
