import React from 'react';
import PropTypes from 'prop-types';
import titleInfo from '../Title/titleInfo';

const StatusMessage = ({ error, loading, zeroFound }) => {
    if (error) {
        return <p>Error :(</p>;
    }
    if (loading) {
        return <p>Loading...</p>;
    }
    if (zeroFound) {
        return <p>No {titleInfo.title}</p>;
    } else {
        return "";
    }
    return null;
};

StatusMessage.propTypes = {
    error: PropTypes.bool,
    loading: PropTypes.bool,
    zeroFound: PropTypes.bool,
};

StatusMessage.defaultProps = {
    error: false,
    loading: false,
    zeroFound: false,
};

export default StatusMessage;