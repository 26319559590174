import {
    HELP_HELPDESKFORM_STATE_INSERT,
    HELP_HELPDESKFORM_STATE_INSERTED,
    HELP_HELPDESKFORM_STATE_FAILURE
} from "../_constants/action-types"

import 'whatwg-fetch';
import { getAdalToken } from '../_helpers/adalToken';
import { takeEvery, call, put } from "redux-saga/effects";
//import { sendRequest } from "../_helpers/util"
import AuthContext from '../services/Auth';

require('dotenv').config({ debug: false })
const url = process.env.REACT_APP_HELP_API_URL + "/api/help";

export function* worker_help(action) {
    //debugger;
    switch (action.type) {
        case HELP_HELPDESKFORM_STATE_INSERT:
            //debugger;
            try {
                const payload = yield call(submitHelpDeskForm, action);
                yield put({ type: HELP_HELPDESKFORM_STATE_INSERTED, "helpdeskforminserted": payload });
            } catch (e) {
                yield put({ type: HELP_HELPDESKFORM_STATE_FAILURE, payload: e });
            }
         break;
    }
}

function submitHelpDeskForm(action) {
    return sendRequest(`${url}/submithelpdeskticket`, action.partialStateValue);
}


function sendRequest(url, params) {
    //debugger;
    const requestOption = {
        method: 'POST',
        //credentials: 'omit',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getAdalToken() },
        body: params
    };

    return fetch(url, requestOption)
    .then(result => {
        if (result.ok) {
            return result.json();
        } else {
            if (result.status === 401) {
                // auto logout if 401 response returned from api
                AuthContext.logOut();
            }
            throw 'error';
        }
    }).then(data => {
        return data;
    });
}