import React from 'react';
import PropTypes from 'prop-types';
import './EarthQuakeListItem.css';
import Moment from 'moment';

function convertDMS(lat, lng) {
    var latitudeCardinal = lat >= 0 ? "N" : "S";

    var longitudeCardinal = lng >= 0 ? "E" : "W";

    if (lat < 0)
        lat = lat * -1;

    if (lng < 0)
        lng = lng * -1;

    return lat.toFixed(3) +  latitudeCardinal + " " + lng.toFixed(3) + longitudeCardinal;
}

function convertKMToMiles(kilometers) {
    var miles = kilometers / 1.6;
    return miles.toFixed(2);
}

const ListItem = ({ id, mag, url, title, longitude, latitude, depth, time }) => (    
    
        <div key={id}>
            <div className='eq-title'><a href={url} target='_blank'>{title}</a></div>
            <div className='eq-time'>{Moment.utc(time).format('MMMM Do, YYYY hh:mm')} (UTC)</div>
            <div className='eq-item'><div className='eq-label'>Location:</div> <div className='eq-label-value'>{convertDMS(latitude, longitude)}</div></div>
            <div className='eq-item'><div className='eq-label'>Depth:</div> <div className='eq-label-value'>{depth} km ({convertKMToMiles(depth)}mi)</div></div>
        </div>
    
);

const ListItem_Orig = ({ id, mag, url, title, longitude, latitude, depth, time }) => (
    <li key={id}>
        {id} : {mag} : {url} : {title} : {longitude}
    </li>
);

ListItem.propTypes = {
    id: PropTypes.string.isRequired,
    mag: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    longitude: PropTypes.string.isRequired,
    latitude: PropTypes.string.isRequired,
    depth: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired
};

export default ListItem;