import { AbilityBuilder, Ability } from 'casl';

function defineAbilityFor(userAbility) {
    //debugger;
    const { can, rules } = AbilityBuilder.extract();

    if (userAbility && userAbility.length > 0) {
        userAbility.forEach(function (item, index) {
            can(item.PermissionName, '');
        });
    }

    return new Ability(rules);

}

export default defineAbilityFor;




