import 'whatwg-fetch';
import { getAdalToken } from '../_helpers/adalToken';
import AuthContext from '../services/Auth';

export const util = {
    sendRequest
};

export function sendRequest(url, method, data) {
    //debugger;
    //const adalToken = localStorage.getItem('adal.idtoken');

    method = method || 'GET';
    data = data || {};

    if (method === 'GET') {
        return fetch(url, {
            method: method,
            //credentials: 'include'
            headers: {
                'Authorization': 'Bearer ' + getAdalToken()
            }
        }).then(result => {
            //debugger;
            if (result.ok) {
                //alert(JSON.stringify(json));
                return result.json();
            }
            else {
                if (result.status === 401) {
                    AuthContext.logOut();
                    window.location.reload();
                }
                throw "error";//json.Message;
            }
        });
    }

    const params = Object.keys(data).map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`;
    }).join('&');

    return fetch(url, {
        method: method,
        body: params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'Authorization': 'Bearer ' + getAdalToken()
        },
        //credentials: 'include'
    }).then(result => {
        //debugger;
        if (result.ok) {
            //return result.text().then(text => text && JSON.parse(text));
            return result.json(); 
        } else {
            //console.log(result.text());
            //return result.json().then(json => {
            //    throw json.Message;
            //});

            if (result.status === 401) {
                AuthContext.logOut();
                window.location.reload();
            }
            throw 'error';

        }
    }).then(function (myJson) {
        //debugger;
        return myJson;
            //console.log(JSON.stringify(myJson));
        });
}