import {
    HELP_HELPDESKFORM_STATE_INSERT,
    HELP_HELPDESKFORM_STATE_INSERTED,
    HELP_HELPDESKFORM_STATE_FAILURE
} from "../_constants/action-types"

export function submitHelpDeskForm(partialStateName, partialStateValue) {
    return {
        type: HELP_HELPDESKFORM_STATE_INSERT,
        partialStateName,
        partialStateValue
    };
}