import earthQuakeDataFormat from '../models/EarthquakeDataFormat';

const parseEarthQuakes = response => response.json()
    .then((json) => {
        let earthQuakes = [];
        if (json && json.features) {
            earthQuakes = json.features.map(earthQuakeDataFormat);
        }
        return earthQuakes;
    });

export default parseEarthQuakes;