import React, { Component } from 'react';
import EarthQuakeList from '../EarthQuakeList/EarthQuakeList';
import StatusMessage from '../StatusMessage/StatusMessage';
import Title from '../Title/Title';

import FetchEarthQuakes from '../../services/fetchEarthQuakes';
import ParseEarthQuakes from '../../services/parseEarthQuakes';

class DisplayEarthQuakeList extends Component {
    constructor() {
        super();
        this.allEarthQuakes = [];
        this.doneLoading = this.doneLoading.bind(this);
        this.setError = this.setError.bind(this);
        this.setEarthQuakes = this.setEarthQuakes.bind(this);
        this.setZeroFound = this.setZeroFound.bind(this);

        this.state = {
            error: false,
            loading: false,
            earthQuakes: [],
            zeroFound: false,
        };
    }

    componentWillMount() {
        this.setState({ loading: true });
    }

    componentDidMount() {
        FetchEarthQuakes()
            .then(ParseEarthQuakes)
            .then((earthQuakes) => {
                this.doneLoading(earthQuakes);
                this.cacheEarthQuakes(earthQuakes);
                this.setEarthQuakes(earthQuakes);
            })
            .catch(this.setError);
    }

    setError(error) {
        const hasError = !!error;
        this.setState({ error: hasError });
    }

    setEarthQuakes(earthQuakes) {
        this.setState({ earthQuakes });
    }

    setZeroFound(zeroFound) {
        this.setState({ zeroFound });
    }

    doneLoading(earthQuakes) {
        this.setState({ loading: false });
        if (earthQuakes.length == 0)
            this.setZeroFound({ zeroFound: true });
    }

    cacheEarthQuakes(earthQuakes) {
        this.allEarthQuakes = earthQuakes.map(x => x);
    }

    render() {
        return (
            <div>
                <Title />
                <StatusMessage
                    error={this.state.error}
                    loading={this.state.loading}
                    zeroFound={this.state.zeroFound}
                />
                <EarthQuakeList
                    earthQuakes={this.state.earthQuakes}
                />
            </div>
        );
    }
}

export default DisplayEarthQuakeList;
