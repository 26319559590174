import {

    //Broadcast_GRID_STATE_LOAD,
    //Broadcast_GRID_STATE_LOADED,

    Broadcast_FORM_SMS_STATE_INSERT,
    Broadcast_Editor_STATE_LOAD,
    Broadcast_Editor_STATE_INSERT,
    Broadcast_Editor_STATE_UPDATE,
    Broadcast_Editor_STATE_REMOVE,

    CHECKINS_GRID_STATE_LOAD,

} from "../_constants/action-types"
import { sendRequest } from "../_helpers"

/* for broadcast module */


//export function loadBroadcastData(partialStateName, partialStateValue) {
//    return {
//        type: Broadcast_GRID_STATE_LOAD,
//        partialStateName,
//        partialStateValue
//    };
//}

//export function fetchBroadcastData(url) {
//    return sendRequest(`${url}/GetBroadcastMessagesList`);
//}

export function getBroadcastTypes(url) {
    return sendRequest(`${url}/GetTypesList`);
}

export function getBroadcastGroups(url) {
    //debugger;
    return sendRequest(`${url}/GetGroupList`);
}

export function insertBroadcastData_FormSMS(partialStateName, partialStateValue) {
    //debugger;
    return {
        type: Broadcast_FORM_SMS_STATE_INSERT,
        partialStateName,
        partialStateValue
      
    };
}

export function loadBroadcastEditor(partialStateName, partialStateValue) {
    //debugger;
    return {
        type: Broadcast_Editor_STATE_LOAD,
        partialStateName,
        partialStateValue
    };
}

export function insertBroadcastEditor(partialStateName, partialStateValue) {
    return {
        type: Broadcast_Editor_STATE_INSERT,
        partialStateName,
        partialStateValue
    };
}

export function updateBroadcastEditor(partialStateName, partialStateValue) {
    return {
        type: Broadcast_Editor_STATE_UPDATE,
        partialStateName,
        partialStateValue
    };
}

export function removeBroadcastEditor(partialStateName, partialStateValue) {
    return {
        type: Broadcast_Editor_STATE_REMOVE,
        partialStateName,
        partialStateValue
    };
}

export function loadCheckinsData(partialStateName, partialStateValue) {
    console.log('loadCheckinsData');
    return {
        type: CHECKINS_GRID_STATE_LOAD,
        partialStateName,
        partialStateValue
    };
}

/* end broadcast module */