import React from 'react';
import { RadioGroup, Button, List } from 'devextreme-react';
import { TreeList, Selection, Column } from 'devextreme-react/tree-list';
import { Item } from 'devextreme-react/list';
import ArrayStore from 'devextreme/data/array_store';
import CustomStore from 'devextreme/data/custom_store';
import ScrollView from 'devextreme-react/scroll-view';
import './mapfiltercontent.scss';

require('dotenv').config({ debug: false })
const URL = process.env.REACT_APP_MAP_API_URL +'/api/Map/Divisions';

class FilterData extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
           
            selectedItemKeys: [1],
            selectedRowKeys:[],
            opened: true,
            viewTypeData: new ArrayStore({
                key: 'id',
                data: [{ id: 1, text: 'All' }, { id: 2, text: 'Checked-In' }]
            }),
            //divisionData: new ArrayStore({
            //    key: 'id',
            //    data: [{ id: 1, text: 'Division 1' }, { id: 2, text: 'Division 2' }]
            //}),
            divisionData: new CustomStore({
                key: 'division',
                loadMode: 'raw',
                load: () => this.GetDivisions(URL)
            }),

        };

        this.onSelectedItemKeysChange = this.onSelectedItemKeysChange.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
    }

    onSelectedItemKeysChange(args) {
        if (args.name === 'selectedItemKeys') {
            this.setState({
                selectedItemKeys: args.value
            });
        }
    }
    onSelectionChanged(e) {
        this.setState({
            selectedRowKeys: e.selectedRowKeys
        });
    }

    handleUpdateMap =(e) => { 
        //debugger;
        const viewtype = this.state.selectedItemKeys;
        const division = this.state.selectedRowKeys;
        this.props.updateMap(viewtype, division);
        e.event.stopPropagation();
    }

    handleCloseFilter = () => {

    }

    GetDivisions(url){
       // debugger;
       return fetch(url)
           .then(r => r.json())
           .then(data => {
              // debugger;
                return data;
            })
    }

    render() {

        return (
            <div className={'map-filterdatacontainer'}>
                <ScrollView id={'scrollview'}>
                    <div className={'dx-fieldset'}>
                        <div className={'row'}>
                            <button
                                className="close-button"
                                onClick={this.closeDetailWindow.bind(this)}
                            >
                                &#10006;
                            </button>
                        <div className={'label'}><b>Viewing Type:</b></div>
                        <div className={'value'}>
                            <List
                                dataSource={this.state.viewTypeData}
                                showSelectionControls={true}
                                selectionMode={'single'}
                                selectAllMode={'page'}
                                selectedItemKeys={this.state.selectedItemKeys}
                                onOptionChanged={this.onSelectedItemKeysChange}
                            >
                            </List>
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'label'}><b>Filter Map By Divisions:</b></div>
                            <div className={'valuescroll'}>
                                <div className={'content'}>
                                <TreeList
                                        dataSource={this.state.divisionData}
                                        showRowLines={false}
                                        //showBorders={false}
                                        //columnAutoWidth={true}
                                        //defaultExpandedRowKeys={expandedRowKeys}
                                        sorting={{ mode: 'none' }}
                                        selectedRowKeys={this.state.selectedRowKeys}
                                        keyExpr={'division'}
                                        //parentIdExpr={'division'}
                                        onSelectionChanged={this.onSelectionChanged}
                                        className={'mapFilterByDiv'}
                                >
                                    <Selection recursive={false} mode={'multiple'} />
                                    <Column dataField={'division'} caption={'Select All'} />
                           
                                </TreeList>
                                </div>
                                <Button
                                    className={'btnUpdateMap'}
                                    type={'default'}
                                    width={'100%'}
                                    height={40}
                                    onClick={this.handleUpdateMap}
                                >
                                    Update Map
                            </Button>
                            </div>
                        </div>
                        
                    </div>
                </ScrollView>
                
            </div>
        );
    }

    closeDetailWindow = () => {
        this.props.closeFilter(!this.state.opened);
    }
}

export default FilterData;