import React from 'react';
import './home.scss';
import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';
import List from 'devextreme-react/list';
import { Button } from 'devextreme-react/button';
import { connect, Provider } from "react-redux";
import { store } from '../../_helpers';
import { fetchData, loadPrivData } from "../../_actions/announcement.actions";

import DataSource from 'devextreme/data/data_source';
import { withRouter } from 'react-router-dom';
//import { ABILITIES } from '../../config/ability';
import defineAbilityFor from '../../config/abilityrule';

export class ListW extends React.Component {

    constructor(props) {
        super(props);

        //const userRole = store.getState().userrole.role;
        const ability = defineAbilityFor(store.getState().userrole.userAbility);

        this.state = {
            announcementData: new CustomStore({
                key: 'ID',
                loadMode: "raw",
                load: this.loadPriv,
            }),
            loading: false,
            loadpriv: [],
            ability: ability//userRole ? ABILITIES[userRole] : ABILITIES.Staffs
        }; // state

    } //constructor

    /*
    dFormat = (dateObj) => {
        var year = dateObj.getFullYear();
        var month = dateObj.getMonth();
        var day = dateObj.getDate();
        var hours = dateObj.getHours()
        var minutes = dateObj.getMinutes()
        var seconds = dateObj.getSeconds();
        if (month < 10) {
            month = "0" + month;
        }
        if (day < 10) {
            day = "0" + day;
        }
        if (hours < 10) {
            hours = "0" + hours;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        if (seconds < 10) {
            seconds = "0" + seconds;
        }

        return month + "/" + day + "/" + year + " " + hours + ":" + minutes + ":" + seconds;
    };*/

    dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/; 

    reviver(value) {
        if (typeof value === "string" && this.dateFormat.test(value)) {
            return new Date(value);
        }

        return value;
    };

    loadPriv(values) {
        var response = store.dispatch(loadPrivData('loadpriv', values));
        console.log(`Load Data`);
        return new Promise((resolve, reject) => {
            const unsubscribe = store.subscribe(() => {
                const { completed, succeeded, loadpriv, loading, ...extraParams } = store.getState().gridWReducer;

                if (!completed) return;
                //debugger;
                unsubscribe();
                if (succeeded) {
                    resolve(loadpriv);
                } else {
                    reject(extraParams.message);
                }
            });
        });
    } // loadData

    render() {

        const { announcementData, ability, loading, loadpriv } = this.state;

        const Announcement = (item) => {
            return (
                <div>
                    <div className={'announcementTitle'}>
                        {item.Title}
                    </div>
                    <div>
                        {item.DateTimePosted}
                    </div>
                    <div className={'announcementContent'}>
                        {item.Message}
                    </div>

                </div>
            );
        }


        return (
            <React.Fragment>
                {ability.can('Announcement List - View', '') &&
                    <div className={'containerbox'}>
                        <div className={'announcementHead'}>
                            <div className={'title'}>
                                Emergency Announcement</div>
                            <div className={'reload'}>
                                <Button
                                className={'reload-button'}
                                icon={'pulldown'}
                                stylingMode={'text'}
                                text={'Refresh'}
                                //onClick={() => this.loadPriv()}
                                onClick={() => { this.list.instance.reload(); }}
                              
                                />
                                {/*
                            <Button
                                className={'manage-button'}
                                icon={'fas fa-tools'}
                                stylingMode={'text'}
                                text={'Manage'}
                                onClick={() => this.props.history.push('/announcement')}
                            />*/}
                            </div>
                        </div>

                        <div className={'list-container'}>

                        <List
                            id={'listPrivateAnnouncement'}
                            ref={(ref) => this.list = ref}
                            dataSource={announcementData}
                            itemRender={Announcement}
                            height={400}
                            activeStateEnabled={false}
                            focusStateEnabled={false}
                            hoverStateEnabled={true}
                            pageLoadMode={'scrollBottom'}
                            noDataText={'There is no announcement. Please check back. Thank you. '}
                            />

                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }

};

function mapDispatchToProps(dispatch) {
    return {
        
    };
}
function mapStateToProps(state) {
    return {
        state: state
    };
}

const ListComp = withRouter(connect(mapStateToProps, mapDispatchToProps)(ListW));

export default () => (
    <React.Fragment>

        <Provider store={store}>
            <ListComp />
        </Provider>
        
    </React.Fragment>
);
