import {
    ANNOUNCEMENT_GRID_STATE_CHANGE,
    ANNOUNCEMENT_GRID_STATE_LOAD,
    ANNOUNCEMENT_GRID_STATE_INSERT,
    ANNOUNCEMENT_GRID_STATE_UPDATE,
    ANNOUNCEMENT_GRID_STATE_REMOVE,
    ANNOUNCEMENT_GRID_STATE_SORTINGCHANGE,
    ANNOUNCEMENT_GRID_STATE_PAGINGCHANGE,
    ANNOUNCEMENT_GRID_STATE_PAGESIZECHANGE,
    ANNOUNCEMENT_PRIVLIST_STATE_LOAD,
    ANNOUNCEMENT_TYPE_STATE_LOAD
} from "../_constants/action-types"

import { sendRequest } from "../_helpers"

export function createGridWAction(partialStateName, partialStateValue) {
    return {
        type: ANNOUNCEMENT_GRID_STATE_CHANGE,
        partialStateName,
        partialStateValue
    };
}

export function changeSorting(partialStateName, partialStateValue) {
    //debugger;
    return {
        type: ANNOUNCEMENT_GRID_STATE_SORTINGCHANGE,
        partialStateName,
        partialStateValue
    };
}

export function loadData(partialStateName, partialStateValue) {
    return {
        type: ANNOUNCEMENT_GRID_STATE_LOAD,
        partialStateName,
        partialStateValue
    };
}

export function fetchData(url) {
    return sendRequest(`${url}`);
}

export function insertData(partialStateName, partialStateValue) {
    return {
        type: ANNOUNCEMENT_GRID_STATE_INSERT,
        partialStateName,
        partialStateValue
    };
}

export function updateData(partialStateName, partialStateValue) {
    //console.log('ACTIONS: ANNOUNCEMENT_GRID_STATE_UPDATE');
    return {
        type: ANNOUNCEMENT_GRID_STATE_UPDATE,
        partialStateName,
        partialStateValue
    };
}

export function removeData(partialStateName, partialStateValue) {
    return {
        type: ANNOUNCEMENT_GRID_STATE_REMOVE,
        partialStateName,
        partialStateValue
    };
}

export function getTypes(url) {
    return sendRequest(`${url}/Types`);
}

export function loadTypes(partialStateName, partialStateValue) {
    return {
        type: ANNOUNCEMENT_TYPE_STATE_LOAD,
        partialStateName,
        partialStateValue
    };
}

export function loadPrivData(partialStateName, partialStateValue) {
    return {
        type: ANNOUNCEMENT_PRIVLIST_STATE_LOAD,
        partialStateName,
        partialStateValue
    };
}

export * from './user.actions';
export * from './alert.actions';