import {
    //Broadcast_GRID_STATE_LOAD,
    //Broadcast_GRID_STATE_LOADED,
    //Broadcast_GRID_STATE_LOADED_ERROR,

    Broadcast_Editor_STATE_LOAD,
    Broadcast_Editor_STATE_LOADED,
    Broadcast_Editor_STATE_LOAD_ERROR,
    Broadcast_Editor_STATE_INSERT,
    Broadcast_Editor_STATE_INSERTED,
    Broadcast_Editor_STATE_INSERT_ERROR,
    Broadcast_Editor_STATE_UPDATE,
    Broadcast_Editor_STATE_UPDATED,
    Broadcast_Editor_STATE_UPDATE_ERROR,
    Broadcast_Editor_STATE_REMOVE,
    Broadcast_Editor_STATE_REMOVED,
    Broadcast_Editor_STATE_REMOVE_ERROR,
    Broadcast_FORM_SMS_STATE_INSERTED,
    Broadcast_FORM_SMS_STATE_INSERT_ERROR,
    CHECKINS_GRID_STATE_LOAD,
    CHECKINS_GRID_STATE_LOADED,
    CHECKINS_GRID_STATE_LOAD_ERROR
} from "../_constants/action-types"
import { takeEvery, call, put } from "redux-saga/effects";
import { sendRequest } from "../_helpers/util"


require('dotenv').config({ debug: false })
const url = process.env.REACT_APP_BROADCAST_API_URL+"/api/Broadcasts";

export function* worker_sms_form(action) { 
    //debugger;
        switch (action.partialStateName) {
            //case "loaddata":
            //    try {
            //        const payload = yield call(loadBroadcastData, action);
            //        yield put({ type: Broadcast_GRID_STATE_LOADED, "loaddata": payload });
            //    } catch (e) {
            //        yield put({ type: Broadcast_GRID_STATE_LOADED_ERROR, payload: e });
            //    }
            //    break;
            case "loadbroadcasteditor":
                try {
                    //debugger;
                    const payload = yield call(loadBroadcastEditor, action);
                    yield put({ type: Broadcast_Editor_STATE_LOADED, "loadbroadcasteditor": payload });
                } catch (e) {
                    //debugger;
                    yield put({ type: Broadcast_Editor_STATE_LOAD_ERROR, payload: e });
                }
                break;
            case "insertbroadcasteditor":
                try {
                    //debugger;
                    const payload = yield call(insertBroadcastEditor, action);
                    yield put({ type: Broadcast_Editor_STATE_INSERTED, "insertbroadcasteditor": payload });
                } catch (e) {
                    //debugger;
                    yield put({ type: Broadcast_Editor_STATE_INSERT_ERROR, payload: e });
                }
                break;
            case "updatebroadcasteditor":
                try {
                    //debugger;
                    const payload = yield call(updateBroadcastEditor, action);
                    yield put({ type: Broadcast_Editor_STATE_UPDATED, "updatebroadcasteditor": payload });
                } catch (e) {
                    //debugger;
                    yield put({ type: Broadcast_Editor_STATE_UPDATE_ERROR, payload: e });
                }
                break;
            case "removebroadcasteditor":
                try {
                    const payload = yield call(removeBroadcastEditor,action);
                    yield put({ type: Broadcast_Editor_STATE_REMOVED, "removebroadcasteditor" : payload });
                } catch (e) {
                    yield put({ type: Broadcast_Editor_STATE_REMOVE_ERROR, payload: e });
                }
                break;
            case "insertsms":
                try {
                    //debugger;
                    const payload = yield call(insertBroadcastSMS, action);
                    yield put({ type: Broadcast_FORM_SMS_STATE_INSERTED, "insertsms": payload });
                } catch (e) {
                    //debugger;
                    yield put({ type: Broadcast_FORM_SMS_STATE_INSERT_ERROR, payload: e });
                }
                break;
            case "loadcheckinsdata":
                try {
                    const payload = yield call(loadCheckinsData, action);
                    yield put({ type: CHECKINS_GRID_STATE_LOADED, "loadcheckinsdata": payload });
                } catch (e) {
                    yield put({ type: CHECKINS_GRID_STATE_LOAD_ERROR, payload: e });
                }
                break;
           
        }
        
}

//function loadBroadcastData(action) {
//    return sendRequest(`${url}/GetBroadcastMessagesList`);
//}
function loadBroadcastEditor(action) {
    return sendRequest(`${url}/LoadBroadcast`);
}

function insertBroadcastEditor(action) {
    return sendRequest(`${url}/InsertBroadcast`, 'POST', action.partialStateValue);
}

function updateBroadcastEditor(action) {
    return sendRequest(`${url}/UpdateBroadcast`, 'PUT', action.partialStateValue);
}

function removeBroadcastEditor(action) {
    return sendRequest(`${url}/DeleteBroadcast`, 'DELETE', action.partialStateValue);
}

function insertBroadcastSMS(action) {
    return sendRequest(`${url}/InsertBroadcastMessagesByForm`, 'POST', action.partialStateValue);

}

function loadCheckinsData(action) {
    debugger;
    return sendRequest(`${url}/ListCheckins`+action.partialStateValue);
}
