
import React from 'react';
import TextBox from 'devextreme-react/text-box';
import ValidationGroup from 'devextreme-react/validation-group';
import Validator, { RequiredRule } from 'devextreme-react/validator';
import Button from 'devextreme-react/button';
//import CheckBox from 'devextreme-react/check-box';
import './login-form.scss';
import appInfo from '../../app-info';
//import { Link } from 'react-router-dom';
import { userActions } from '../../_actions/user.actions';
import { connect } from 'react-redux';
import { textAlign } from '@material-ui/system';
import { Center } from 'devextreme-react/map';
import { history } from '../../_helpers';
import { Loading } from '../../theme-sources/material-ui/components/loading';

import { LoadPanel } from 'devextreme-react/load-panel';

//Kim's
import sblogo from '../../img/sb_cologo180.jpg';

import AdalConfig from '../../config/AdalConfig';
import AuthContext from '../../services/Auth';

import { store } from '../../_helpers';

const LoadingPos = { of: '#loginPanel' };

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

      this.state = {
          employeeid: '',
          password: '',
          submitted: false,
          loggingIn: false,
          //roleRetrieved: false,
          profile:[],
          profileRetrieved: false,
          userAbility: [],
          abilityRetrieved:false
      };

      this.handleChange = this.handleChange.bind(this);
      this.unsubscribeRoleListener = store.subscribe(this.listenForDispatch.bind(this));      
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    listenForDispatch() {
        //debugger;
        const userAbility = store.getState().userrole.userAbility;
        if (userAbility && userAbility.length>0) {

            this.unsubscribeRoleListener();
            this.props.logIn();
           
        }
    }

    componentDidMount() {
        AuthContext.handleWindowCallback();
        if ((window === window.parent) && window === window.top && !AuthContext.isCallback(window.location.hash)) {
            AuthContext.acquireToken(AdalConfig.endpoints.api, (message, token, msg) => {
                if (token) {

                    const user = AuthContext.getCachedUser();
                    if (user) {
                        //debugger;
                        //this.getUserRole(user.userName);
                        this.getUserAbility(user.userName);
                        this.getUserProfile(user.userName);
                        //this.props.logIn();
                    }
                }
            });
        }
    }

    componentWillUnmount() {
    }

    render() {
        const { loggingIn, type, message, loggedIn, role, user, profile } = this.props;

        var handleToUpdate = this.props.handleToUpdate;
        return (
            <div className={'login-form'} id={'loginPanel'}>
                <LoadPanel
                    shadingColor={'rgba(0,0,0,0.4)'}
                    position={LoadingPos}
                    visible={loggingIn}
                    showIndicator={true}
                    message={"Please wait.."}
                    shading={true}
                    showPane={true}
                />
                <ValidationGroup className='vgroup'>
                    <div className={'login-caption'} >
                        <img className='image' src={sblogo} width={180} />
                        <div className={'login-header'}>
                            <div className={'title'}>{appInfo.title}</div>
                            <div className={'subtitle'}>Sign in to your account</div>
                        </div>
                    
                        <div className={'message'}>
                            For San Bernardino County - Auditor-Controller/Treasurer/Tax Collector
                            employee only.  </div>
                        <div className={'dx-field'}>
                            <Button
                                type={'default'}
                                text={'Login'}
                                onClick={() => AuthContext.login()}
                                width={'100%'}
                                className={'login-button'}
                            />
                    </div>
                    {type == "alert-danger" ? <div style={{ color: 'red', textAlign: Center }}>{message ? message : null}</div> : null}
                    {//{loggingIn && <Loading />}
                    }
                    {loggedIn}
                    </div>
                </ValidationGroup>
            </div >
        );
    }

    getUserRole = (employeeid) => {
        const { dispatch } = this.props;
        //debugger;
        dispatch(userActions.getUserRole(employeeid));
    };

    getUserAbility = (userName) => {
        //debugger;
        const { dispatch } = this.props;
        dispatch(userActions.getUserAbility(userName));
    }

    getUserProfile = (userName) => {
        const { dispatch } = this.props;
        dispatch(userActions.getUserProfile(userName));
    }
}

function mapStateToProps(state) {
    //debugger;
    const { loggingIn, loggedIn, role,  profile, profileRetrieved } = state.userrole;
    const { type, message } = state.alert;

    return {
        loggingIn, type, message, loggedIn, role,  profile, profileRetrieved
    };
}

const connectedLoginPage = connect(mapStateToProps)(LoginForm);
export { connectedLoginPage as LoginForm }; 