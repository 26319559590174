import {
    ANNOUNCEMENT_GRID_STATE_CHANGE,
    ANNOUNCEMENT_GRID_STATE_LOAD,
    ANNOUNCEMENT_GRID_STATE_LOADED,
    ANNOUNCEMENT_GRID_STATE_LOAD_ERROR,
    ANNOUNCEMENT_GRID_STATE_INSERT,
    ANNOUNCEMENT_GRID_STATE_INSERTED,
    ANNOUNCEMENT_GRID_STATE_INSERT_ERROR,
    ANNOUNCEMENT_GRID_STATE_UPDATE,
    ANNOUNCEMENT_GRID_STATE_UPDATED,
    ANNOUNCEMENT_GRID_STATE_UPDATE_ERROR,
    ANNOUNCEMENT_GRID_STATE_REMOVE,
    ANNOUNCEMENT_GRID_STATE_REMOVED,
    ANNOUNCEMENT_GRID_STATE_REMOVE_ERROR,
    ANNOUNCEMENT_PRIVLIST_STATE_LOAD,
    ANNOUNCEMENT_PRIVLIST_STATE_LOADED, 
    ANNOUNCEMENT_PRIVLIST_STATE_LOAD_ERROR,
    ANNOUNCEMENT_TYPE_STATE_LOAD,
    ANNOUNCEMENT_TYPE_STATE_LOADED,
    ANNOUNCEMENT_TYPE_STATE_LOAD_ERROR,
} from "../_constants/action-types"

const gridWInitialState = {
    announcementsData: [],
    annoucementTypesData: [],
    requests: [],
    events: [],
    refreshMode: 'reshape',
    updated: false,
    completed: false,
    loaddata:[]
};

function gridWReducer(state = gridWInitialState, action) {
    if (action.type === ANNOUNCEMENT_GRID_STATE_CHANGE) {
        return {
            ...state,
            [action.partialStateName]: action.partialStateValue,
        };
    }

    //LOAD PRIV LIST
    if (action.type === ANNOUNCEMENT_PRIVLIST_STATE_LOAD) {
        return {
            ...state,
            [action.partialStateName]: action.partialStateValue,
            loading: true,
        };
    }
    if (action.type === ANNOUNCEMENT_PRIVLIST_STATE_LOADED) {
        return Object.assign({}, state, {
            loadpriv: action.loadpriv,
            completed: true,
            succeeded: true,
            loading: false,
        });
    }
    if (action.type === ANNOUNCEMENT_PRIVLIST_STATE_LOAD_ERROR) {
        return Object.assign({}, state, {
            [action.partialStateName]: action.partialStateValue,
            completed: true,
            succeeded: false,
            apiError: true,
            message: 'Operation failed!',
            loading: false,
        });
    }

    // LOAD
    if (action.type === ANNOUNCEMENT_GRID_STATE_LOAD) {
        return {
            ...state,
            [action.partialStateName]: action.partialStateValue,
        };
    }
    if (action.type === ANNOUNCEMENT_GRID_STATE_LOADED) {
        //debugger;
        return Object.assign({}, state, {
            loaddata: action.loaddata,
            completed: true,
            succeeded: true
        });
    }
    if (action.type === ANNOUNCEMENT_GRID_STATE_LOAD_ERROR) {
        return Object.assign({}, state, {
            [action.partialStateName]: action.partialStateValue,
            completed: true,
            succeeded: false,
            apiError: true,
            message: 'Operation failed!'
        });
    }

    //INSERT
    if (action.type === ANNOUNCEMENT_GRID_STATE_INSERT) {
        return {
            ...state,
            [action.partialStateName]: action.partialStateValue,
        };
    }
    if (action.type === ANNOUNCEMENT_GRID_STATE_INSERTED) {
        return Object.assign({}, state, {
            [action.partialStateName]: action.partialStateValue,
            insert: action.insert,
            completed: true,
            succeeded: true
        });
    }
    if (action.type === ANNOUNCEMENT_GRID_STATE_INSERT_ERROR) {
        return Object.assign({}, state, {
            [action.partialStateName]: action.partialStateValue,
            completed: true,
            succeeded: false,
            apiError: true,
            message: 'Operation failed!'
        });
    }

    // UPDATE
    if (action.type === ANNOUNCEMENT_GRID_STATE_UPDATE) {
        //console.log('REDUCER: ANNOUNCEMENT_GRID_STATE_UPDATE');
        return {
            ...state,
            [action.partialStateName]: action.partialStateValue,
        };
    }
    if (action.type === ANNOUNCEMENT_GRID_STATE_UPDATED) {
        //console.log('REDUCER: ANNOUNCEMENT_GRID_STATE_UPDATED');
        return Object.assign({}, state, {
            [action.partialStateName]: action.partialStateValue,
            updated: true,  // for testing purpose only
            completed: true,
            succeeded: true
        });
    }
    if (action.type === ANNOUNCEMENT_GRID_STATE_UPDATE_ERROR) {
        //console.log('REDUCER: ANNOUNCEMENT_GRID_STATE_UPDATE_ERROR');
        return Object.assign({}, state, {
            [action.partialStateName]: action.partialStateValue,
            updated: false, // for testing purpose only
            completed: true,
            succeeded: false,
            apiError: true,
            message: 'Operation failed!'
        });
    }

    // DELETE
    if (action.type === ANNOUNCEMENT_GRID_STATE_REMOVE) {
        return {
            ...state,
            [action.partialStateName]: action.partialStateValue,
        };
    }
    if (action.type === ANNOUNCEMENT_GRID_STATE_REMOVED) {
        return Object.assign({}, state, {
            [action.partialStateName]: action.partialStateValue,
            completed: true,
            succeeded: true
        });
    }
    if (action.type === ANNOUNCEMENT_GRID_STATE_REMOVE_ERROR) {
        return Object.assign({}, state, {
            [action.partialStateName]: action.partialStateValue,
            completed: true,
            succeeded: false,
            apiError: true,
            message: 'Operation failed!'
        });
    }

    // LOAD TYPES
    if (action.type === ANNOUNCEMENT_TYPE_STATE_LOAD) {
        return {
            ...state,
            [action.partialStateName]: action.partialStateValue,
        };
    }
    if (action.type === ANNOUNCEMENT_TYPE_STATE_LOADED) {
        //debugger;
        return Object.assign({}, state, {
            loadtypes: action.loadtypes,
            completed: true,
            succeeded: true
        });
    }
    if (action.type === ANNOUNCEMENT_TYPE_STATE_LOAD_ERROR) {
        return Object.assign({}, state, {
            [action.partialStateName]: action.partialStateValue,
            completed: true,
            succeeded: false,
            apiError: true,
            message: 'Operation failed!'
        });
    }

    return state;
}

export default gridWReducer;