import 'whatwg-fetch';
import { getAdalToken } from '../_helpers/adalToken';
import { userConstants} from '../_constants';
import { call, put } from "redux-saga/effects";
import AuthContext from '../services/Auth';

require('dotenv').config({ debug: false })
const url = process.env.REACT_APP_AUTH_API_URL + "/api/users";


//export const userService = {
//    //login,
//    //logout,
//    //getAll,
//    getUserRole
//};

export function* worker_login(action) {
    //debugger;
    switch (action.type) {
        //case userConstants.LOGIN_REQUEST:
        //    try {
        //        const payload = yield call(login, action);
        //        yield put({ type: userConstants.LOGIN_SUCCESS, "user": payload });
        //    } catch (e) {
        //        yield put({ type: userConstants.LOGIN_FAILURE, payload: e });
        //    }
        //    break;
        //case userConstants.GETROLE_REQUEST:
        //    try {
        //        const payload = yield call(getUserRole, action);
        //        //debugger;
        //        yield put({ type: userConstants.GETROLE_SUCCESS, "role": payload });
        //    } catch (e) {
        //        yield put({ type: userConstants.GETROLE_FAILURE ,payload: e });
        //    }
        //    break;
        case userConstants.USERS_GETABILITY_REQUEST:
            try {
                const payload = yield call(getUserAbility, action);
                //debugger;
                yield put({ type: userConstants.USERS_GETABILITY_SUCCESS, "userAbility": payload });
            } catch (e) {
                //debugger;
                yield put({ type: userConstants.USERS_GETABILITY_FAILURE, payload: e });
            }
            break;
        case userConstants.GETPROFILE_REQUEST:
            //debugger;
            try {
                const payload = yield call(getUserProfile, action);
                yield put({ type: userConstants.GETPROFILE_SUCCESS, "profile": payload });
            } catch (e) {
                yield put({ type: userConstants.GETPROFILE_FAILURE, payload: e });
            }
            break;
        case userConstants.CHECKIN_INSERT:
            //debugger;
            try {
                const payload = yield call(checkIn, action);
                yield put({ type: userConstants.CHECKIN_INSERTED, "checkininserted": payload });
            } catch (e) {
                yield put({ type: userConstants.CHECKIN_FAILURE, payload: e });
            }
            break;
        //case userConstants.LOGOUT:
        //    try {
        //        const payload = yield call(logout, action);
        //        yield put({ type: userConstants.LOGOUT_SUCCESS, "user": payload });
        //    } catch (e) {
        //        yield put({ type: userConstants.LOGOUT_FAILURE, payload: e });
        //    }
        //    break;
        //case userConstants.GETALL_REQUEST:
        //    try {
        //        const payload = yield call(getAll, action);
        //        yield put({ type: userConstants.GETALL_SUCCESS, "users": payload });
        //    } catch (e) {
        //        yield put({ type: userConstants.GETALL_FAILURE, "users": e });
        //    }
        //    break;
    }
}

//function login(action) {
//    var employeeid = action.username;
//    var password = action.password;
//    const requestOptions = {
//        method: 'POST',
//        headers: { 'Content-Type': 'application/json' },
//        body: JSON.stringify({ employeeid, password }),
//        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
//        mode: 'cors', // no-cors, cors, *same-origin
//        referrer: 'no-referrer' // no-referrer, *client
//    };
//    //alert(`${config.apiUrl}/users/authenticate`);
//    //return fetch(`${config.apiUrl}/users/authenticate`, requestOptions)
//    //debugger;

//    return fetch('http://localhost:22823/api/users/authenticate', requestOptions)
//    //return fetch('http://atc-nash-dev/atceapi-auth/api/users/authenticate', requestOptions)
//        .then(handleResponse)
//        .then(user => {
//            // store user details and jwt token in local storage to keep user logged in between page refreshes
//            //debugger;
//            localStorage.setItem('user', JSON.stringify(user));

//            return user;
//        });
//}

//function getUserRole(action) {
//    //const adalToken = localStorage.getItem('adal.idtoken');
//    const employeeid = action.employeeid;
//    //debugger;
//    const requestOptions = {
//        method: 'POST',
//        credentials: 'omit',
//        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getAdalToken() },
//        body: JSON.stringify({ employeeid })
//    };

//    return fetch(`${url}/getuserrole`, requestOptions)
//        .then(handleResponse)
//        .then(userrole => {
//            return JSON.parse(userrole).RoleTitle;
//        });
//}

//function getAll(action) {
//    const requestOptions = {
//        method: 'GET',
//        headers: authHeader()
//    };

//    return fetch(`${config.apiUrl}/users`, requestOptions).then(handleResponse);
//    return fetch(`/users`, requestOptions).then(handleResponse);
//}

//function handleResponse(response) {
//    //debugger;
//    return response.text().then(text => {
//        //console.log('ERROR: ' + text);
//        //debugger;
//        const data = text;// && JSON.parse(text);
//        if (!response.ok) {
//            if (response.status === 401) {
//                // auto logout if 401 response returned from api
//                //logout();
//                //window.location.reload(true);

//                AuthContext.logOut();
                
//            }

//            //const error = (data && data.message) || response.statusText;
//            const error = data || data.message || response.statusText;
//            return Promise.reject(error);
//        }

//        return data;
//    });
//}

function getUserAbility(action) {
    return sendRequest(`${url}/GetUserAbility`, JSON.stringify({ AzureAdusername: action.userName }));
}

function getUserProfile(action) {
    return sendRequest(`${url}/GetUserProfile`, JSON.stringify({ AzureAdusername: action.userName }));
}

function checkIn(action) {
    return sendRequest(`${url}/CheckIn`, action.partialStateValue);
}

function sendRequest(url,params) {
    const requestOption = {
        method: 'POST',
        //credentials: 'omit',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getAdalToken() },
        body: params
    };

    return fetch(url, requestOption)
        .then(result => {
            if (result.ok) {
                return result.json();
            } else {
                if (result.status === 401) {
                    // auto logout if 401 response returned from api
                    AuthContext.logOut();
                }
                throw 'error';
            }
        }).then(data => {
            return data;
        });
}


