
export const navigation = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'Announcements',
        path: '/announcement',
        icon: 'folder',
        visible: false
    },
    {
        text: 'Directory',
        path: '/directory',
        icon: 'fa fa-users',
        visible: true
    },
    {
        text: 'Map',
        path: '/map',
        icon: 'map',
        visible: true
    },
    {
        text: 'Broadcast',
        path: '/broadcastmain',
        icon: 'comment',
        visible: true
    },
    {
        text: 'View Check-Ins',
        path: '/checkinlist',
        icon: 'fas fa-user-check',
        visible: true
    },
    {
        text: 'Help',
        path: '/help',
        icon: 'fas fa-info-circle',
        visible: false
    }];
