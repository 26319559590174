import {
    Broadcast_FORM_SMS_STATE_INSERT,
    Broadcast_FORM_SMS_STATE_INSERTED,
    Broadcast_FORM_SMS_STATE_INSERT_ERROR
} from "../_constants/action-types"

const formSMSInitialState = {
    BroadCastGroups: [],
    requests: [],
    events: [],
    refreshMode: 'reshape',
    completed: false,
    succeeded: false
};

function formSMSReducer(state = formSMSInitialState, action) {
 //debugger;
    if (action.type === Broadcast_FORM_SMS_STATE_INSERT) {
        debugger;
        return {
            ...state,
            [action.partialStateName]: action.partialStateValue,
        };
    }
    if (action.type === Broadcast_FORM_SMS_STATE_INSERTED) {
        debugger;
        return Object.assign({}, state, {
            [action.partialStateName]: action.partialStateValue,
            completed: true,
            succeeded: true
        });
    }
    if (action.type === Broadcast_FORM_SMS_STATE_INSERT_ERROR) {
        debugger;
        return Object.assign({}, state, {
            [action.partialStateName]: action.partialStateValue,
            completed: true,
            succeeded: false,
            apiError: true,
            message: 'Operation failed!'
           
        });
    }
    return state;
}
export default formSMSReducer;