import React, { Component } from 'react';
import { Drawer } from 'devextreme-react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { Button } from 'devextreme-react/button';
import FilterContent from './filtercontent.js'
import ReactGoogleMaps from './reactgooglemap.js'
import './filter.scss';


export default class filter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: false,
            viewtype: [1],
            division:[]
        }

        this.toolbarItems = [{
            widget: 'dxButton',
            location: 'after',
            options: {
                icon: 'menu',
                onClick: () => this.setState({ opened: !this.state.opened })
            }
        }];
    }

    updateMap = (viewtype, division) => {
        //debugger;
        this.setState({ viewtype: viewtype, division: division, opened: !this.state.opened });
    }

    closeFilter = (opened) => {
        this.setState({ opened: opened });
    }

    render() {

        return (
            <React.Fragment>
            <div className="map-container">
                <div className="map-header">
                        {/*<div className={"title"}>
                            <span className={"pagetitle"}>Map</span>
                        </div>*/}
                    <div >
                            {/*<Button
                            className={"reload-button"}
                            icon={"pulldown"}
                            stylingMode={"text"}
                            text={"Refresh"}
                            onClick={() => {
                                this.setState({
                                    employees: [],
                                    currentPage: -1,
                                    awaitMore: true,
                                    isLoading: false,
                                })
                            }}
                            /> */}
                            <Toolbar>
                                <Item
                                    widget={'dxButton'}
                                    location={'after'}
                                    cssClass={"reload-button"}
                                    options={{
                                        icon: 'pulldown',
                                        stylingMode:  'text',
                                        text: 'Refresh',
                                        onClick: () => {
                                            this.setState({
                                                employees: [],
                                                currentPage: -1,
                                                awaitMore: true,
                                                isLoading: false,
                                            })
                                        }
                                }}
                                
                            />
                                <Item
                                    widget={'dxButton'}
                                    location={'before'}
                                    cssClass={'mapFilterIcon'}
                                    options={{
                                        icon: 'menu',
                                        onClick: () => this.setState({ opened: !this.state.opened }),
                                        
                                    }}
                                />
                                <Item
                                    location={'before'}
                                    cssClass={'pagetitle'}
                                    text={'Map'}
                                />
                            </Toolbar>
                    </div>
                </div>
                    <div className="map-content">
                        <Drawer
                            opened={this.state.opened}
                            openedStateMode={'overlap'}
                            position={'left'}
                            revealMode={'slide'}
                            closeOnOutsideClick={true}
                            height={'80vh'}
                            shading={true}
                            //maxSize={window.innerWidth <= 1024 ? 470 : 300}
                            //component={FilterContent}
                            render={() => <FilterContent updateMap={this.updateMap} closeFilter={this.closeFilter} />}
                        >
                            <div id={'content'} className={'dx-theme-background-color'}>

                                {/*
                                this.state.viewtype[0] == 1
                                    ?
                                    <img src="http://cms.sbcounty.gov/portals/30/GIS%20MAPS/Web%20GIS%20Map%202.jpg" width="100%" height="100%" alt="basemap" />
                                    :
                                    <img src="https://www.mapsofworld.com/usa/county-maps/california/maps/san-bernardino-county-map.jpg" width="100%" height="100%" alt="basemap" />

                                */}
                                {
                                    <ReactGoogleMaps viewtype={this.state.viewtype} division={this.state.division} />
                                }
                            </div>
                        </Drawer>
                </div>
            </div>

            <div>
                
            </div>
            </React.Fragment>
            );
    }
}
