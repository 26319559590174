import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import 'devextreme/dist/css/dx.common.css';
import React, { Component } from 'react';
import { HashRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import appInfo from './app-info';
import { navigation } from './app-navigation';
import routes from './app-routes';
import './App.scss';
import './dx-styles.scss';
import { Footer, LoginForm, CheckInForm } from './components';
//import { PrivateRoute } from './components/routes/PrivateRoute';
import {
    SideNavInnerToolbar as SideNavBarLayout
} from './layouts'; // Replaceable: SideNavInnerToolbar or SideNavOuterToolbar
import { sizes, subscribe, unsubscribe } from './utils/media-query';

import { connect, Provider } from 'react-redux';
import { userActions } from './_actions/user.actions';
import { HomePage, LoginPage } from './pages';
import { history } from './_helpers';

//Kim's
import EarthQuakeList from "./components/earthquake-panel/components/DisplayEarthquakeList/DisplayEarthquakeList";
import InfoBoard from "./components/info/Info";
import { TabPanel, Item } from "devextreme-react/tab-panel";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import sherifflogo from "./img/SheriffMainLogo_final.png";
import usgslogo from "./img/USGS_logo.png";
import noaalogo from "./img/468px-NOAA_logo.svg.png";
import calfirelogo from "./img/Image 1.png";
import cdclogo from "./img/US_CDC_logo.png";
import { ScrollView } from "devextreme-react";
import WeatherScraper from "./utils/weather-scraper/weather-scraper";
import CalFireScraper from "./utils/calfire-scraper/calfire-scraper";
import AnnouncementDisplay from './components/announcement-display/AnnouncementDisplay';

import { store } from './_helpers';
import AdalConfig from './config/AdalConfig';
import AuthContext from './services/Auth';

const LoginContainer = ({ logIn, handleToUpdate }) => <Provider store={store}><LoginForm logIn={logIn} handleToUpdate={handleToUpdate.bind(this)} /></Provider>;

const NotAuthPage = (props) => (
    <div className="login-container">
        <div className="page">
            <span className="logos">
                <img src={cdclogo} alt={"logo"} className="logo cdclogo" />
                <img src={sherifflogo} alt={"logo"} className="logo sherifflogo" />
                <img src={usgslogo} alt={"logo"} className="logo usgslogo" />
                <img src={noaalogo} alt={"logo"} className="logo" />
                <img src={calfirelogo} alt={"logo"} className="logo" />
            </span>
            <span className="left">
                <Route render={() => <LoginContainer {...props} />} />
            </span>
            <span className="right">

                <InfoBoard className="board" title="Public Annoucement">
                    <AnnouncementDisplay />
                </InfoBoard>

                <TabPanel className="tab-panel">
                    <Item title={"CDC"}>
                        <TwitterTimelineEmbed
                            sourceType="profile"
                            screenName="CDCgov"
                            autoHeight
                        />
                    </Item>
                    <Item title={"USGS"}>
                        <ScrollView className="usgs">
                            <EarthQuakeList />
                        </ScrollView>
                    </Item>
                    <Item title={"weather"}>
                        <ScrollView className="weather">
                            <WeatherScraper />
                        </ScrollView>
                    </Item>
                    <Item title="cal fire">
                        <ScrollView className="calfire">
                            <CalFireScraper />
                        </ScrollView>
                    </Item>
                    <Item title={"SB sheriff"}>
                        <TwitterTimelineEmbed
                            sourceType="profile"
                            screenName="sbcountysheriff"
                            autoHeight
                        />
                    </Item>
                </TabPanel>
            </span>
        </div>
    </div>
);

const AuthPage = (props) => (
  <SideNavBarLayout menuItems={navigation} title={appInfo.title} {...props}>
        <Switch>
            {routes.map(item => (
                <Route
                    exact
                    key={item.path}
                    path={item.path}
                    component={item.component}
                />
            ))}
            <Redirect to={'/home'} />
        </Switch>
        {/*
        <Footer>
            2019 Auditor-Controller/Treasurer/Tax Collector. 
            ATC Emergency Application. Build: 0.0.0.1
        </Footer> */}
        <CheckInForm />	
    </SideNavBarLayout>
);

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loggedIn: false,
            screenSizeClass: this.getScreenSizeClass()
        };
        var handleToUpdate = this.handleToUpdate.bind(this);
        this.gotoAnnouncement = this.gotoAnnouncement.bind(this);

        this.userMenuItems = [
            {
                text: 'Manage Announcement',
                icon: 'fas fa-tools',
                //onClick: this.gotoAnnouncement
                path: '/announcement'
            },
            {
                text: 'Logout',
                icon: 'fas fa-sign-out-alt',
                onClick: this.logOut
            },
            {
                text: 'Help Info',
                icon: 'fas fa-info-circle',
                path: '/help'
            }
        ];

    }

    componentDidMount() {
        subscribe(this.screenSizeChanged);
    }

    componentWillUnmount() {
        unsubscribe(this.screenSizeChanged);
    }

    handleToUpdate(val) {
        console.log('pass value from child to parent: ' + val);
        this.setState({ loggedIn: val });
    }

    render() {
        const { loggedIn, history } = this.state;
        var handleToUpdate = this.handleToUpdate;
        return (
            <div className={`app ${this.state.screenSizeClass}`}>
                <Router>
                    {loggedIn ? <AuthPage userMenuItems={this.userMenuItems} /> : <NotAuthPage logIn={this.logIn} handleToUpdate={handleToUpdate.bind(this)} />}
                </Router>
            </div>
        );
    }

    getScreenSizeClass() {
        const screenSizes = sizes();
        return Object.keys(screenSizes).filter(cl => screenSizes[cl]).join(' ');
    }

    screenSizeChanged = () => {
        this.setState({
            screenSizeClass: this.getScreenSizeClass()
        });
    }

    logIn = () => {
        //debugger;
        this.setState({ loggedIn: true });
    };

    logOut = () => {
        AuthContext.logOut();
        localStorage.removeItem('role');
    };
    gotoAnnouncement = () => {
        console.log('app.js - onclick');
        console.log(this.props);
    }

}

function mapDispatchToProps(dispatch) {
    return {
        _logOut: value => { dispatch(userActions.logout()); }
    };
}
function mapStateToProps(state) {
    return {
        loggedIn: state.loggedIn
    };
}

const AppPage = connect(mapStateToProps, mapDispatchToProps)(App);

export default () => (<Provider store={store}><AppPage /></Provider>);
