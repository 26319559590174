import React from 'react';
import './broadcast.scss';
import { DataGrid, Column, Editing, Scrolling, Paging, Lookup, Position, Form, Popup, RequiredRule, SearchPanel, HeaderFilter, FilterRow } from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';
import { Item } from 'devextreme-react/form';
import CustomStore from 'devextreme/data/custom_store';
import { loadBroadcastEditor, insertBroadcastEditor, updateBroadcastEditor, removeBroadcastEditor } from "../../_actions/broadcast.actions";
import { connect, Provider } from "react-redux";
import { store } from "../../_helpers";
import { sendRequest } from "../../_helpers/util";
import BroadcastTagBoxComponent from './broadcasttagboxcomponent';
import defineAbilityFor from '../../config/abilityrule';
import { FormItem, Sorting } from 'devextreme-react/tree-list';
import AuthContext from '../../services/Auth';

import './broadcasteditor.scss';

require('dotenv').config({ debug: false })
const url = process.env.REACT_APP_BROADCAST_API_URL + '/api';


class BroadcastEditor extends React.Component {
    constructor(props) {
        super(props);

        const ability = defineAbilityFor(store.getState().userrole.userAbility);

        this.state = {
            BroadcastData: new CustomStore({
                key: 'Id',
                //loadMode: 'raw',
                load: this.loadData,
                insert:(values)=> this.insertData(values),
                update: this.updateByKey,
                remove: this.deleteByKey,
            }),
            BroadCastGroups: new CustomStore({
                key: 'Id',
                loadMode: 'raw',
                load: () => sendRequest(url + '/Groups/GetGroupList')
            }),
            refreshMode: 'reshape',
            ability: ability
        };

    }

    loadData(values) {
        //debugger;
        const response = store.dispatch(loadBroadcastEditor('loadbroadcasteditor', values));

        return new Promise((resolve, reject) => {
            const unsubscribe = store.subscribe(() => {
                const { completed, succeeded, loadbroadcasteditor, ...extraParams } = store.getState().broadcastGridReducer;
                //debugger;
                if (!completed) return;

                unsubscribe();
                if (succeeded) {
                    resolve(loadbroadcasteditor);
                } else {
                    reject(extraParams.message);
                }
            });
        });
    }

    insertData=(values)=> {
        //debugger;
        //values.userName = AuthContext.getCachedUser().userName;
        //values.profileName = AuthContext.getCachedUser().profile.name;
        const profile = store.getState().userrole.profile;
        if (profile) {
            values.EmployeeId = profile[0].EmployeeId;
            values.UserName = profile[0].FirstName + ' ' + profile[0].LastName;
        }

        store.dispatch(insertBroadcastEditor('insertbroadcasteditor', {
            values: JSON.stringify(values)
        }));

        
        //const gridBroadcast = this.dataGrid;

        return new Promise((resolve, reject) => {
            const unsubscribe = store.subscribe(() => {
                const { completed, succeeded, insertbroadcasteditor, ...extraParams } = store.getState().broadcastGridReducer;
              
                if (!completed) return;

                unsubscribe();
                //debugger;
                if (succeeded) {
                    resolve(insertbroadcasteditor);
                    //gridBroadcast.instance.refresh();
                } else {
                    reject(extraParams.message);
                }
            });
        });
    }

    updateByKey(key, values) {
        store.dispatch(updateBroadcastEditor('updatebroadcasteditor', {
            key: key,
            values: JSON.stringify(values)
        }));
        console.log(`Updating #${key}`);

        return new Promise((resolve, reject) => {
            const unsubscribe = store.subscribe(() => {
                const { completed, succeeded, ...extraParams } = store.getState().broadcastGridReducer;

                if (!completed) return;

                unsubscribe();

                if (succeeded) {
                    resolve();
                } else {
                    reject(extraParams.message);
                }
            });
        });
    }

    deleteByKey(key) {
        store.dispatch(removeBroadcastEditor('removebroadcasteditor', { key: key }));
        console.log(`Deleting #${key}`);
        return new Promise((resolve, reject) => {
            const unsubscribe = store.subscribe(() => {
                const { completed, succeeded, ...extraParams } = store.getState().broadcastGridReducer;

                if (!completed) return;

                unsubscribe();

                if (succeeded) {
                    resolve();
                } else {
                    reject(extraParams.message);
                }
            });
        });
    }

    cellTemplate(container, options) {
        var noBreakSpace = '\u00A0',
            text = (options.value || []).map(element => {
                return options.column.lookup.calculateCellValue(element);
            }).join(', ');
        container.textContent = text || noBreakSpace;
        container.title = text;
    }

    componentDidMount() { };
    componentDidUpdate() { };

    render() {

        const { refreshMode, BroadcastData, BroadCastGroups, ability } = this.state;

        return (
            <React.Fragment>

                <Provider store={store}>
                    <DataGrid
                        id={'grid'}
                        //ref={(ref) => this.dataGrid = ref}
                        dataSource={BroadcastData}
                        showBorders={true}
                        showRowLines={true}
                        showColumnLines={false}
                        repaintChangesOnly={true}

                    >
                        <Paging enabled={true} pageIndex={0} pageSize={20}/>
                        <FilterRow visible={true}/>
                        <SearchPanel visible={true}
                            width={240}
                            placeholder={'Search...'} />
                        <HeaderFilter visible={false} />
                        <Sorting mode={'multiple'}/>
                        <Scrolling
                            mode={'virtual'}
                        />
                        <Editing
                            refreshMode={refreshMode}
                            mode={'popup'}
                            allowAdding={ability.can('Broadcast Manage - Send SMS', '') ? true : false}
                            //allowUpdating={ability.can('Broadcast Manage - Modify SMS', '') ? true : false}
                            //allowDeleting={ability.can('Broadcast Manage - Delete SMS', '') ? true : false}
                            texts={{ saveRowChanges:'Send' }}
                        >
                            <Popup title={'Compose Broadcast Message'} showTitle={true} width={700} height={525}>
                                <Position my={'top'} at={'top'} of={window} />
                            </Popup> 
                        </Editing>
                        <Column dataField={'DateTimePosted'}
                            dataType={'date'}
                            format={'shortDateShortTime'}
                            caption={'Sent'}
                            sortOrder={'desc'}
                            allowEditing={false} width={'20%'}>
                            <FormItem visible={false} />
                        </Column>
                        <Column dataField={'broadcastmessage'} caption={'Text Message'}>
                            <RequiredRule />
                            <FormItem
                                editorType={'dxTextArea'}
                                editorOptions={{ height: 100, maxLength: 160, hint: 'Maximum 160 characters', placeholder: 'Enter Text Message. Maximum 160 characters.', stylingMode: 'filled' }}
                                colSpan={2}
                                
                            />
                            
                        </Column>
                        <Column
                            dataField={'selectedgroup'}
                            caption={'Broadcast Message to Group'}
                            editCellComponent={BroadcastTagBoxComponent}
                            cellTemplate={this.cellTemplate}
                            width={'26%'}>
                            <Lookup
                                dataSource={BroadCastGroups}
                                valueExpr={'Id'}
                                displayExpr={'GroupName'}
                            />
                            <RequiredRule />
                            <FormItem colSpan={2}/>
                        </Column>

                    </DataGrid>
                </Provider>

            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {

    };
}
function mapStateToProps(state) {
    return {
        state: state.broadcastGridReducer
    };
}

const Grid = connect(mapStateToProps, mapDispatchToProps)(BroadcastEditor);

export { Grid as BroadcastEditor };

