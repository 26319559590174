import React, { Component } from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import UserPanel from '../user-panel/user-panel';
import './header.scss';
import { Template } from 'devextreme-react/core/template';
import { withRouter } from 'react-router-dom';
import { connect, Provider } from 'react-redux';
import { store } from '../../_helpers';

class header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }

        this.onClick = this.onClick.bind(this);
    }

    render() {
        const { menuToggleEnabled, title, toggleMenu, userMenuItems } = this.props;
        return (
            <header className={'header-component'}>
                <Toolbar className={'header-toolbar'}>
                    <Item
                        visible={menuToggleEnabled}
                        location={'before'}
                        widget={'dxButton'}
                        cssClass={'menu-button'}
                        options={{
                            icon: 'menu',
                            stylingMode: 'text',
                            onClick: toggleMenu
                        }}
                    />
                    <Item
                        location={'before'}
                        cssClass={'header-title'}
                        text={title}
                        visible={!!title}
                    />
                    <Item
                        location={'after'}
                        cssClass={'header-helpdesk'}
                        options={{
                            icon: 'fas fa-info-circle',
                            stylingMode: 'text',
                            onClick: this.onClick
                        }}
                        widget={'dxButton'}
                    />
                    <Item
                        location={'after'}
                        locateInMenu={'auto'}
                        menuItemTemplate={'userPanelTemplate'}
                    >
                        <Button
                            className={'user-button authorization'}

                            height={'100%'}
                            stylingMode={'text'}
                        >
                            <UserPanel menuItems={userMenuItems} menuMode={'context'} />
                        </Button>
                    </Item>
                    <Template name={'userPanelTemplate'}>
                        <Provider store={store}>
                            <UserPanel menuItems={userMenuItems} menuMode={'list'} store={store} />
                        </Provider>
                    </Template>
                </Toolbar>
            </header>
        )
    }

    onClick(args) {
        //debugger;
        var objIndex = this.props.userMenuItems.findIndex((obj => obj.text == 'Help Info'));
        const path = this.props.userMenuItems[objIndex].path;
        const click = this.props.userMenuItems[objIndex].onClick;

        if (path && !click) {
            this.props.history.push(path);
        }
    }
}

export default withRouter(header);

