import React from 'react';
import PropTypes from 'prop-types';
import EarthQuakeListItem from '../EarthQuakeListItem/EarthQuakeListItem';

const EarthQuakeList = ({ earthQuakes }, index) => (
    <div key={index}>
        { earthQuakes.map(EarthQuakeListItem) }
    </div>
);

EarthQuakeList.propTypes = {
    earthQuakes: PropTypes.array,
};

EarthQuakeList.defaultProps = {
    earthQuakes: [],
};

export default EarthQuakeList;