import React, { Component } from "react";
import "./SmallField.scss";



class SmallField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    renderSwitch(type, content) {
        switch (type) {
            case 'mailto':
                return (<span><i class="far fa-envelope email"></i><a href={`mailto:${content}`} className={'emaillink'}>
                    <div className="field-content">{content}</div></a></span>);
            case 'tel':
                return (<span><i class="fas fa-phone-alt phone"></i><a href={`tel:${content}`} className={'phonelink'}>
                    <div className="field-content">{content}</div></a></span>);
            default:
                return <div className="field-content">{content}</div>
        }
    }

    render() {
        return (
            <div className={this.props.className}>
                <div className="field">
                    <div className="field-title">{this.props.title}</div>
                    {this.renderSwitch(this.props.fieldType, this.props.content)}
                </div>
            </div>
        )
    }
};

export default SmallField;
