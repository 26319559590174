import React, { Component } from 'react';
import { Drawer } from 'devextreme-react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { Button } from 'devextreme-react/button';
import './filterpage.scss';
import HTMLReactParser from 'html-react-parser';
import { text } from './data.js';
import NavigationList from './NavigationList.js';

class filterpage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: false,
            viewtype: [1],
            division: []
        }

        this.toolbarItems = [{
            widget: 'dxButton',
            location: 'after',
            options: {
                icon: 'menu',
                onClick: () => this.setState({ opened: !this.state.opened })
            }
        }];
    }


    render() {

        return (
            <React.Fragment>
                <div className="map2-container">
                    <div className="map2-header">
                        <div className={"title"}>
                            <span className={"pagetitle"}>Map2</span>
                        </div>
                        <div>
                            <Toolbar>
                                <Item
                                    widget={'dxButton'}
                                    location={'after'}
                                    cssClass={"reload-button"}
                                    options={{
                                        icon: 'pulldown',
                                        stylingMode: 'text',
                                        text: 'Refresh',
                                        onClick: () => {
                                            this.setState({
                                                employees: [],
                                                currentPage: -1,
                                                awaitMore: true,
                                                isLoading: false,
                                            })
                                        }
                                    }}

                                />
                                <Item
                                    widget={'dxButton'}
                                    location={'after'}
                                    options={{
                                        icon: 'menu',
                                        onClick: () => this.setState({ opened: !this.state.opened })
                                    }}
                                />
                            </Toolbar>
                        </div>
                    </div>
                    <div className="map2-content">
                        <Drawer
                            opened={this.state.opened}
                            openedStateMode={'overlap'}
                            position={'left'}
                            revealMode={'slide'}
                            closeOnOutsideClick={true}
                            //height={'80vh'}
                            //maxSize={window.innerWidth <= 1024 ? 470 : 300}
                            //render={() => { }}
                            component={NavigationList}
                        >
                            <div id={'content'} className={'dx-theme-background-color'}>
                                {HTMLReactParser(text)}
                            </div>
                        </Drawer>
                    </div>
                </div>

                <div>

                </div>
            </React.Fragment>
        );
    }
}

export default filterpage;