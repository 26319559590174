export const ANNOUNCEMENT_GRID_STATE_CHANGE = 'ANNOUNCEMENT_GRID_STATE_CHANGE';

export const ANNOUNCEMENT_GRID_STATE_LOAD = "ANNOUNCEMENT_GRID_STATE_LOAD";
export const ANNOUNCEMENT_GRID_STATE_LOADED = "ANNOUNCEMENT_GRID_STATE_LOADED";
export const ANNOUNCEMENT_GRID_STATE_LOAD_ERROR = "ANNOUNCEMENT_GRID_STATE_LOAD_ERROR";

export const ANNOUNCEMENT_TYPE_STATE_LOAD = "ANNOUNCEMENT_TYPE_STATE_LOAD";
export const ANNOUNCEMENT_TYPE_STATE_LOADED = "ANNOUNCEMENT_TYPE_STATE_LOADED";
export const ANNOUNCEMENT_TYPE_STATE_LOAD_ERROR = "ANNOUNCEMENT_TYPE_STATE_LOAD_ERROR";

export const ANNOUNCEMENT_GRID_STATE_INSERT = "ANNOUNCEMENT_GRID_STATE_INSERT";
export const ANNOUNCEMENT_GRID_STATE_INSERTED = "ANNOUNCEMENT_GRID_STATE_INSERTED";
export const ANNOUNCEMENT_GRID_STATE_INSERT_ERROR = "ANNOUNCEMENT_GRID_STATE_INSERT_ERROR";

export const ANNOUNCEMENT_GRID_STATE_UPDATE = "ANNOUNCEMENT_GRID_STATE_UPDATE";
export const ANNOUNCEMENT_GRID_STATE_UPDATED = "ANNOUNCEMENT_GRID_STATE_UPDATED";
export const ANNOUNCEMENT_GRID_STATE_UPDATE_ERROR = "ANNOUNCEMENT_GRID_STATE_UPDATE_ERROR";

export const ANNOUNCEMENT_GRID_STATE_REMOVE = "ANNOUNCEMENT_GRID_STATE_REMOVE";
export const ANNOUNCEMENT_GRID_STATE_REMOVED = "ANNOUNCEMENT_GRID_STATE_REMOVED";
export const ANNOUNCEMENT_GRID_STATE_REMOVE_ERROR = "ANNOUNCEMENT_GRID_STATE_REMOVE_ERROR";

export const ANNOUNCEMENT_GRID_STATE_SORTINGCHANGE = "ANNOUNCEMENT_GRID_STATE_SORTINGCHANGE";
export const ANNOUNCEMENT_GRID_STATE_PAGINGCHANGE = "ANNOUNCEMENT_GRID_STATE_PAGINGCHANGE";
export const ANNOUNCEMENT_GRID_STATE_PAGESIZECHANGE = "ANNOUNCEMENT_GRID_STATE_PAGESIZECHANGE";

export const ANNOUNCEMENT_PRIVLIST_STATE_LOAD = "ANNOUNCEMENT_PRIVLIST_STATE_LOAD";
export const ANNOUNCEMENT_PRIVLIST_STATE_LOADED = "ANNOUNCEMENT_PRIVLIST_STATE_LOADED";
export const ANNOUNCEMENT_PRIVLIST_STATE_LOAD_ERROR = "ANNOUNCEMENT_PRIVLIST_STATE_LOAD_ERROR";

export const DIRECTORY_GRID_STATE_CHANGE = "DIRECTORY_GRID_STATE_CHANGE";
export const DIRECTORY_GRID_STATE_LOAD = "DIRECTORY_GRID_STATE_LOAD";
export const DIRECTORY_GRID_STATE_SORTINGCHANGE = "DIRECTORY_GRID_STATE_SORTINGCHANGE";
export const DIRECTORY_PRIVLIST_STATE_LOAD = "DIRECTORY_PRIVLIST_STATE_LOAD";

export const DIRECTORY_GRID_STATE_LOADED = "DIRECTORY_GRID_STATE_LOADED";
export const DIRECTORY_GRID_STATE_LOAD_ERROR = "DIRECTORY_GRID_STATE_LOAD_ERROR";
export const DIRECTORY_GRID_STATE_INSERT = "DIRECTORY_GRID_STATE_INSERT";
export const DIRECTORY_GRID_STATE_REMOVE = "DIRECTORY_GRID_STATE_REMOVE";
export const DIRECTORY_GRID_STATE_UPDATE = "DIRECTORY_GRID_STATE_UPDATE";
export const DIRECTORY_PRIVLIST_STATE_LOADED = "DIRECTORY_PRIVLIST_STATE_LOADED";
export const DIRECTORY_PRIVLIST_STATE_LOAD_ERROR = "DIRECTORY_PRIVLIST_STATE_LOAD_ERROR";

export const Broadcast_FORM_SMS_STATE_INSERT = "Broadcast_FORM_SMS_STATE_INSERT";
export const Broadcast_FORM_SMS_STATE_INSERTED = "Broadcast_FORM_SMS_STATE_INSERTED";
export const Broadcast_FORM_SMS_STATE_INSERT_ERROR = "Broadcast_FORM_SMS_STATE_INSERT_ERROR";

export const Broadcast_Editor_STATE_LOAD = "Broadcast_Editor_STATE_LOAD";
export const Broadcast_Editor_STATE_LOADED = "Broadcast_Editor_STATE_LOADED";
export const Broadcast_Editor_STATE_LOAD_ERROR = "Broadcast_Editor_STATE_LOAD_ERROR";
export const Broadcast_Editor_STATE_INSERT = "Broadcast_Editor_STATE_INSERT";
export const Broadcast_Editor_STATE_INSERTED = "Broadcast_Editor_STATE_INSERTED";
export const Broadcast_Editor_STATE_INSERT_ERROR = "Broadcast_Editor_STATE_INSERT_ERROR";
export const Broadcast_Editor_STATE_UPDATE = "Broadcast_Editor_STATE_UPDATE";
export const Broadcast_Editor_STATE_UPDATED = "Broadcast_Editor_STATE_UPDATED";
export const Broadcast_Editor_STATE_UPDATE_ERROR = "Broadcast_Editor_STATE_UPDATE_ERROR";
export const Broadcast_Editor_STATE_REMOVE = "Broadcast_Editor_STATE_REMOVE";
export const Broadcast_Editor_STATE_REMOVED = "Broadcast_Editor_STATE_REMOVED";
export const Broadcast_Editor_STATE_REMOVE_ERROR = "Broadcast_Editor_STATE_REMOVE_ERROR";

export const CHECKINS_GRID_STATE_LOAD = "CHECKINS_GRID_STATE_LOAD";
export const CHECKINS_GRID_STATE_LOADED = "CHECKINS_GRID_STATE_LOADED";
export const CHECKINS_GRID_STATE_LOAD_ERROR = "CHECKINS_GRID_STATE_LOAD_ERROR";

export const HELP_HELPDESKFORM_STATE_INSERT = "HELP_HELPDESKFORM_STATE_INSERT";
export const HELP_HELPDESKFORM_STATE_INSERTED = "HELP_HELPDESKFORM_STATE_INSERTED";
export const HELP_HELPDESKFORM_STATE_FAILURE = "HELP_HELPDESKFORM_STATE_FAILURE";