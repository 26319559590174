const earthquakeDataFormat = earthquake => ({
    id: earthquake.id,
    mag: earthquake.properties.mag,
    url: earthquake.properties.url,
    title: earthquake.properties.title,
    longitude: earthquake.geometry.coordinates[0],
    latitude: earthquake.geometry.coordinates[1],
    depth: earthquake.geometry.coordinates[2],
    time: earthquake.properties.time
});

export default earthquakeDataFormat;