import { combineReducers } from 'redux';

import { userrole } from './authentication.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import gridWReducer from './announcement-grid-reducer';
import formSMSReducer from './broadcast-formsms-reducer';
import broadcastGridReducer from './broadcast-grid-reducer';
import checkinsGridWReducer from './checkins-grid-reducer';
import { helpReducer } from './help.reducer';

const rootReducer = combineReducers({
    userrole,
    users,
    alert,
    gridWReducer: gridWReducer,
    formSMSReducer,
    broadcastGridReducer,
    checkinsGridWReducer,
    helpReducer
});

export default rootReducer;