import React, { Component } from "react";
import cheerio from "cheerio";
import "./weather-scraper.scss";


class WeatherScraper extends Component {
    state = {
        data: [
            {
                title: "",
                url: ""
            }
        ],
        checkNoData: null
    };

    componentDidMount() {
        this.getDataFromApi();
    }

    getDataFromApi = () => {
        //fetch("https://alerts.weather.gov/cap/mp.php?x=1") // Marianas
        fetch("https://alerts.weather.gov/cap/ca.php?x=1") // California
            //fetch("https://alerts.weather.gov/cap/ms.php?x=1") // Mississippi
            .then(response => response.text())
            .then(data => {
                const $ = cheerio.load(data);

                var collection = [];

                $("title").each(function (i, element) {
                    var id = i;
                    var a = $(this);
                    var link = a.next();
                    var area = link.next();
                    var content = {
                        id: id,
                        title: a.text(),
                        url: link.attr("href"),
                        area: area.text()
                    };

                    collection.push(content);
                });

                this.setState({ checkNoData: checkNoData, data: collection });

                var checkNoData;
                checkNoData = data.match(/There are no active watches, warnings or advisories/)[0];
                this.setState({ checkNoData: checkNoData, data: collection });
            })
            .catch(error => {
                this.setState({ error: error });
            });
    };

    render() {
        const thisData = this.state.data;
        const checkNoData = this.state.checkNoData;

        return (
            <div className="weather">
                <h5 className="title">{thisData[0].title}</h5>
                <hr />
                {(!checkNoData)
                    ? <div>
                        {
                            thisData
                                .filter(data => data.id > 0)
                                .map(data => (
                                    <p key={data.id}>
                                        <a href={data.url}>{data.title}</a>
                                    </p>
                                ))
                        }
                    </div>
                    : <p className="no-data-notice">{checkNoData}</p>}
                <p className="subtitle">
                    Click <a href="https://alerts.weather.gov/">here</a> for other states
                    and listing by county.
                </p>
            </div>
        );
    }
}

export default WeatherScraper;

{/** {(checkNoData != null)

                        ? <p>{checkNoData}</p>
                        : (
                            //console.log("hello", displayData),
                            displayData
                        )
                    }

const displayData = (thisData) => {
            thisData
                .filter(data => data.id > 0)
                .map(data => (
                    <p>
                        <a href={data.url}>{data.title}</a>
                    </p>
                ))
        };
                    **/}
