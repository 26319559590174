export { default as HomePage } from './home/home';
export { LoginPage } from './login/index';
export { default as ProfilePage } from './profile/profile';
export { default as DisplayDataPage } from './display-data/display-data';
export { default as AnnoucementPage } from './announcement/announcement';
export { default as DirectoryPage } from './directory/directory';
//export { default as MapPage } from './map/reactgooglemap'; //'./map/mapwithmarkers'; /*'./map/map'*/
export { default as MapPage } from './map/filter';
export { default as MapPage2 } from './map2/filterpage';

export { default as BroadcastNewPage } from './broadcast/broadcast';
export { default as BroadcastMainPage } from './broadcastmain/broadcasttab';

export { default as CheckinListPage } from './checkin/list';
export { default as HelpMainPage } from './help/help';