import { HomePage, LoginPage, DisplayDataPage, ProfilePage, AnnoucementPage, DirectoryPage, MapPage, MapPage2, BroadcastMainPage, CheckinListPage, HelpMainPage } from './pages';

export default [
    {
        path: '/display-data',
        component: DisplayDataPage
    },
    {
        path: '/profile',
        component: ProfilePage
    },
    {
        path: '/home',
        component: HomePage
    },
    {
        path: '/login',
        component: LoginPage
    },
    {
        path: '/announcement',
        component: AnnoucementPage
    },
    {
        path: '/directory',
        component: DirectoryPage
    },
    {
        path: '/map',
        component: MapPage
    }, {
        path: '/map2',
        component: MapPage2
    },
    {
        path: '/broadcastmain',
        component: BroadcastMainPage
    },
    {
        path: '/checkinlist',
        component: CheckinListPage
    },
    {
        path: '/help',
        component: HelpMainPage
    }
];
