
import { worker_announcement_grid } from './announcement.service';
import { worker_sms_form } from './broadcast.service';
import { worker_login } from './user.service';
import { worker_help } from './help.service';
import {
    ANNOUNCEMENT_GRID_STATE_CHANGE,
    ANNOUNCEMENT_GRID_STATE_LOAD,
    ANNOUNCEMENT_GRID_STATE_LOADED,
    ANNOUNCEMENT_GRID_STATE_LOAD_ERROR,
    ANNOUNCEMENT_GRID_STATE_INSERT,
    ANNOUNCEMENT_GRID_STATE_INSERTED,
    ANNOUNCEMENT_GRID_STATE_INSERT_ERROR,
    ANNOUNCEMENT_GRID_STATE_UPDATE,
    ANNOUNCEMENT_GRID_STATE_UPDATED,
    ANNOUNCEMENT_GRID_STATE_UPDATE_ERROR,
    ANNOUNCEMENT_GRID_STATE_REMOVE,
    ANNOUNCEMENT_GRID_STATE_REMOVED,
    ANNOUNCEMENT_GRID_STATE_REMOVE_ERROR,
    ANNOUNCEMENT_PRIVLIST_STATE_LOAD,
    ANNOUNCEMENT_PRIVLIST_STATE_LOADED,
    ANNOUNCEMENT_PRIVLIST_STATE_LOAD_ERROR,

    //Broadcast_GRID_STATE_LOAD,
    //Broadcast_GRID_STATE_LOADED,
    Broadcast_Editor_STATE_LOAD,
    Broadcast_Editor_STATE_INSERT,
    Broadcast_Editor_STATE_UPDATE,
    Broadcast_Editor_STATE_REMOVE,
    Broadcast_FORM_SMS_STATE_INSERT,

    ANNOUNCEMENT_TYPE_STATE_LOAD,
    ANNOUNCEMENT_TYPE_STATE_LOADED,
    ANNOUNCEMENT_TYPE_STATE_LOAD_ERROR,

    CHECKINS_GRID_STATE_LOAD,
    HELP_HELPDESKFORM_STATE_INSERT

} from "../_constants/action-types"

import { takeEvery, call, put } from "redux-saga/effects";

import { userConstants, alertConstants } from '../_constants';

//const url = "http://localhost:4633/api/v1/Announcement";
//const url = "http://atc-nash-dev/atceapi-announcement/api/v1/Announcement";

export default function* watcher() {
    console.log('WATCHER');

    yield takeEvery(ANNOUNCEMENT_PRIVLIST_STATE_LOAD, worker_announcement_grid);
    yield takeEvery(ANNOUNCEMENT_GRID_STATE_LOAD, worker_announcement_grid);
    yield takeEvery(ANNOUNCEMENT_GRID_STATE_UPDATE, worker_announcement_grid);
    yield takeEvery(ANNOUNCEMENT_GRID_STATE_REMOVE, worker_announcement_grid);
    yield takeEvery(ANNOUNCEMENT_GRID_STATE_INSERT, worker_announcement_grid);
    yield takeEvery(ANNOUNCEMENT_TYPE_STATE_LOAD, worker_announcement_grid);

    yield takeEvery(userConstants.LOGIN_REQUEST, worker_login);
    yield takeEvery(userConstants.GETROLE_REQUEST, worker_login);
    yield takeEvery(userConstants.GETPROFILE_REQUEST, worker_login);
    yield takeEvery(userConstants.LOGOUT, worker_login);
    yield takeEvery(userConstants.CHECKIN_INSERT, worker_login);

    yield takeEvery(userConstants.USERS_GETABILITY_REQUEST, worker_login);

    yield takeEvery(Broadcast_FORM_SMS_STATE_INSERT, worker_sms_form);
  
    yield takeEvery(Broadcast_Editor_STATE_LOAD, worker_sms_form);
    yield takeEvery(Broadcast_Editor_STATE_INSERT, worker_sms_form);
    yield takeEvery(Broadcast_Editor_STATE_UPDATE, worker_sms_form);
    yield takeEvery(Broadcast_Editor_STATE_REMOVE, worker_sms_form);

    yield takeEvery(CHECKINS_GRID_STATE_LOAD, worker_sms_form);

    //2020-03-27 Add Submit Help Desk
    yield takeEvery(HELP_HELPDESKFORM_STATE_INSERT, worker_help);
}
//export * from './broadcast.service';