import React from 'react';
import './broadcast.scss';
import List, { Item } from 'devextreme-react/list';
import TabPanel from 'devextreme-react/tab-panel';
//import { Broadcastmain } from './broadcastmain.js';
//import { BroadcastGrid } from './broadcastlist.js';
import { BroadcastEditor }  from './broadcasteditor.js';
import { ScrollView } from 'devextreme-react';
import { store } from '../../_helpers';
import defineAbilityFor from '../../config/abilityrule';


class broadcast extends React.PureComponent {
    constructor(props) {
        super(props);

        const ability = defineAbilityFor(store.getState().userrole.userAbility);

        this.state =
            {
                selectedIndex: 0,
                ability: ability
            };
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
    }

    onSelectionChanged(args) {
        if (args.name == 'selectedIndex') {
            this.setState({
                selectedIndex: args.value
            });
        }
    }

    changeTabIndex = (index) => {
        this.setState({ selectedIndex:index });
    }

    render() {
        const { selectedIndex, ability } = this.state;
        return (

            <React.Fragment>
                <div className={'content-block'}>
                    <div className={'dx-card responsive-paddings'}>
                        <div className={'title-padding'}>
                            <span className={'pagetitle'}>Broadcast Message</span>
                        </div>

                        <TabPanel className="tab-panel"
                            // height={600}
                            //dataSource={companies}
                            selectedIndex={selectedIndex}
                            onOptionChanged={this.onSelectionChanged}
                            // repaintChangesOnly={false}
                            //loop={loop}
                            // itemTitleRender=  //{this.itemTitleRender}
                            // itemComponent={CompanyItem}
                            //animationEnabled={animationEnabled}
                            //swipeEnabled={swipeEnabled} 
                        >
                            {/*ability.can('Broadcast Manage - Send SMS', '')&&
                                <Item title={"Send By Text"}>
                                    <ScrollView >
                                        <Broadcastmain changeTabIndex={this.changeTabIndex} />
                                    </ScrollView>
                                </Item>
                            */}
                            
                            {ability.can('Broadcast Manage - View SMS', '') &&
                                <Item title={"Send By Text"}  >
                                    <ScrollView >
                                    <BroadcastEditor />
                                    </ScrollView>
                                </Item>
                               
                            }
                            

                        </TabPanel>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default broadcast;