import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createSagaMiddleware from "redux-saga"
//import { createLogger } from 'redux-logger';
import rootReducer from '../_reducers';
import service from "../_services/index"

//const loggerMiddleware = createLogger();
const initSagaMiddleware = createSagaMiddleware();
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
    rootReducer,
    composeEnhancer(applyMiddleware(
        //thunkMiddleware
        initSagaMiddleware
    )),
);

initSagaMiddleware.run(service);

export default store;