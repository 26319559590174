/* Jason's MSDN Tenant
export default {
    clientId: '8d4294de-e6e0-42ef-927d-aa4682a0e829',
  endpoints: {
    api: "8d4294de-e6e0-42ef-927d-aa4682a0e829" // Necessary for CORS requests, for more info see https://github.com/AzureAD/azure-activedirectory-library-for-js/wiki/CORS-usage
  },
  // 'tenant' is the Azure AD instance.
    tenant: 'ac49da03-293d-429e-950c-f72d78c2cf4a',
  // 'cacheLocation' is set to 'sessionStorage' by default (see https://github.com/AzureAD/azure-activedirectory-library-for-js/wiki/Config-authentication-context#configurable-options.
  // We change it to'localStorage' because 'sessionStorage' does not work when our app is served on 'localhost' in development.
  cacheLocation: 'localStorage'
}
*/
// Charles's MSDN Tenant
/*
export default {
    clientId: '15459413-b427-4306-9fd1-0ca2a9792a65',
    endpoints: {
        api: "15459413-b427-4306-9fd1-0ca2a9792a65" // Necessary for CORS requests, for more info see https://github.com/AzureAD/azure-activedirectory-library-for-js/wiki/CORS-usage
    },
    // 'tenant' is the Azure AD instance.
    tenant: '1ea2397d-0f5e-41cb-a391-e438aebb6b5b',
    // 'cacheLocation' is set to 'sessionStorage' by default (see https://github.com/AzureAD/azure-activedirectory-library-for-js/wiki/Config-authentication-context#configurable-options.
    // We change it to'localStorage' because 'sessionStorage' does not work when our app is served on 'localhost' in development.
    cacheLocation: 'localStorage'
} */
// Ming's
/*s
export default {
    clientId: '8737fea1-8a3a-44fb-9810-7ee939404510',
    endpoints: {
        api: "8737fea1-8a3a-44fb-9810-7ee939404510" // Necessary for CORS requests, for more info see https://github.com/AzureAD/azure-activedirectory-library-for-js/wiki/CORS-usage
    },
    // 'tenant' is the Azure AD instance.
    tenant: '4136b46b-9520-419f-96e2-20505a4c77ee',
    // 'cacheLocation' is set to 'sessionStorage' by default (see https://github.com/AzureAD/azure-activedirectory-library-for-js/wiki/Config-authentication-context#configurable-options.
    // We change it to'localStorage' because 'sessionStorage' does not work when our app is served on 'localhost' in development.
    cacheLocation: 'sessionStorage'
} */
// Prod's
export default {
    clientId: '2421cd27-1aef-4668-b2a0-9bf1899d546a',
    endpoints: {
        api: "2421cd27-1aef-4668-b2a0-9bf1899d546a" // Necessary for CORS requests, for more info see https://github.com/AzureAD/azure-activedirectory-library-for-js/wiki/CORS-usage
    },
    // 'tenant' is the Azure AD instance.
    tenant: 'd508ed90-bedd-4084-9083-d6ef55ea07f5',
    // 'cacheLocation' is set to 'sessionStorage' by default (see https://github.com/AzureAD/azure-activedirectory-library-for-js/wiki/Config-authentication-context#configurable-options.
    // We change it to'localStorage' because 'sessionStorage' does not work when our app is served on 'localhost' in development.
    cacheLocation: 'sessionStorage'
}