import {
    HELP_HELPDESKFORM_STATE_INSERT,
    HELP_HELPDESKFORM_STATE_INSERTED,
    HELP_HELPDESKFORM_STATE_FAILURE
} from "../_constants/action-types"

const helpReducerInitialState = {
    
};

export function helpReducer(state = helpReducerInitialState, action) {
    switch (action.type) {
        
        case HELP_HELPDESKFORM_STATE_INSERT:
            //debugger;
            return {
                ...state,
                [action.partialStateName]: action.partialStateValue,
                helpDeskSubmitCompleted: false
            };
        case HELP_HELPDESKFORM_STATE_INSERTED:
            //debugger;
            return Object.assign({}, state, {
                [action.partialStateName]: action.partialStateValue,
                helpDeskSubmitCompleted: true,
            });
        case HELP_HELPDESKFORM_STATE_FAILURE:
            //debugger;
            return Object.assign({}, state, {
                [action.partialStateName]: action.partialStateValue,
                helpDeskSubmitCompleted: false,
                message: 'Help Desk Form Submission Failed!'
            });
        default:
            return state
    }
}