import "./helpdeskform.scss";
import React from 'react';
import Form, {
    SimpleItem,
    ButtonItem,
    Item,
    EmailRule,
    PatternRule,
    RequiredRule
} from 'devextreme-react/form'
import notify from 'devextreme/ui/notify';
import 'devextreme-react/autocomplete';
import { connect, Provider } from "react-redux";
import { store } from '../../_helpers';
import { submitHelpDeskForm } from '../../_actions/help.actions.js'

class HelpDeskForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
            employeeID: store.getState().userrole.profile[0].EmployeeId,
            firstName: store.getState().userrole.profile[0].FirstName,
            lastName: store.getState().userrole.profile[0].LastName,
            phone: '',
            description: '',
            charactersLeft: 2000,
            buttonDisabled: '',
            profile: store.getState().userrole.profile
        };

        this.employeeIDEditorOption = {
            onValueChanged: this.onEmployeeIDValueChanged,
            value: this.state.employeeID
        }
        this.firstnameEditorOption = {
            onValueChanged: this.onFirstNameValueChanged,
            value: this.state.firstName
        }
        this.lastnameEditorOption = {
            onValueChanged: this.onLastNameValueChanged,
            value: this.state.lastName
        }
        this.phoneEditorOptions = {
            mask: '+1 (X00) 000-0000',
            maskRules: {
                X: /[02-9]/
            },
            useMaskedValue: true,
            maskInvalidMessage: 'The phone must have a correct USA phone format',
            onValueChanged: this.onPhoneValueChanged
        };
        this.helpDescriptionOptions = {
            maxLength: 2000,
            onValueChanged: this.onTextAreaValueChanged,
            onKeyUp: this.onTextAreaKeyUp,
            placeholder: 'Add helpdesk description here ...'
        };
        this.labelCount = {
            text: 'Test'
        };
        this.buttonOptions = {
            text: 'Submit',
            type: 'success',
            useSubmitBehavior: true,
            width: 140,
            height: 46
        };
        this.onEmployeeIDValueChanged = this.onEmployeeIDValueChanged.bind(this);
        this.onFirstNameValueChanged = this.onFirstNameValueChanged.bind(this);
        this.onLastNameValueChanged = this.onLastNameValueChanged.bind(this);
        this.onPhoneValueChanged = this.onPhoneValueChanged.bind(this);
        this.onTextAreaKeyUp = this.onTextAreaKeyUp.bind(this);
        this.onTextAreaValueChanged = this.onTextAreaValueChanged.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onInit = this.onInit.bind(this);
    }

    render() {

        
        //debugger;
        return (
            <React.Fragment>
                <div className={'form-container'}>
                    <div className={'info-text'}>
                        To submit ATC Helpdesk ticket, please complete the following information. All fields are required.
                    </div>

                    <form action="" onSubmit={this.handleSubmit}>
                    <Form
                        readOnly={false}
                        showColonAfterLabel={true}
                        showValidationSummary={true}
                        labelLocation={"left"}
                        validationGroup="helpDeskData"
                        colCount={1}
                        onInitialized={this.onInit}
                        >
                            <SimpleItem dataField="Employee ID"  editorOptions={this.employeeIDEditorOption}>
                                <RequiredRule message="Employee ID is required" />
                                <PatternRule message="Must contain alpha-numeric"
                                    pattern={/^(?:[0-9]+[a-z]|[a-z]+[0-9])[a-z0-9]*$/i} />
                            </SimpleItem>
                            <SimpleItem dataField="Firstname" editorOptions={this.firstnameEditorOption}>
                                <RequiredRule message="Firstname is required" />
                                <PatternRule message="Do not use digits in the firstname"
                                    pattern={/^[^0-9]+$/} />
                            </SimpleItem>
                            <SimpleItem dataField="Lastname" editorOptions={this.lastnameEditorOption}>
                                <RequiredRule message="Lastname is required" />
                                <PatternRule message="Do not use digits in the lastname"
                                    pattern={/^[^0-9]+$/} />
                            </SimpleItem>
                            <SimpleItem dataField="Phone"
                                helpText="Enter the phone number where we can reach you"
                                editorOptions={this.phoneEditorOptions}
                                cssClass={'phone'}

                            >
                                <RequiredRule message="Phone is required" />
                                <PatternRule
                                    message="The phone must have a correct USA phone format"
                                    pattern={/^\+\s*1\s*\(\s*[02-9]\d{2}\)\s*\d{3}\s*-\s*\d{4}$/}
                                />
                            </SimpleItem>
                            <SimpleItem dataField="Helpdesk Description"
                                editorType="dxTextArea"
                                editorOptions={this.helpDescriptionOptions}
                            >
                                <RequiredRule message="Description is required" />
                            </SimpleItem>
                            <SimpleItem cssClass={'char-count'}>{this.state.charactersLeft} characters left</SimpleItem>
                            <ButtonItem ref="submitBtn" horizontalAlignment="left" disabled={this.state.buttonDisabled}
                                buttonOptions={this.buttonOptions}
                                cssClass={'submit-button'}
                            />
                    </Form>

                    </form>
                </div>
            </React.Fragment>
        );
    }

    onInit(args) {
        
    }

    //EmployeeID
    onEmployeeIDValueChanged = (e) => {
        this.setState({
            employeeID: e.value
        })
    }
    //Firstname
    onFirstNameValueChanged = (e) => {
        this.setState({
            firstName: e.value
        })
    }
    //Lasttname
    onLastNameValueChanged = (e) => {
        this.setState({
            lastName: e.value
        })
    }
    //Phone
    onPhoneValueChanged = (e) => {
        this.setState({
            phone: e.value
        })
    }
    //Description
    onTextAreaValueChanged = (e) => {
        this.setState({
            description: e.value
        });
    }
    onTextAreaKeyUp = (e) => {
        const charactersLeft = 2000 - (e.event.target.value).length;

        this.setState({ charactersLeft: charactersLeft });
    }

    handleSubmit(e) {
        //debugger;
        
        const myObj = {
            EmployeeID: this.state.employeeID,
            FirstName: this.state.firstName,
            LastName: this.state.lastName,
            Phone: this.state.phone,
            Description: this.state.description
        };

        const values = JSON.stringify(myObj);

        store.dispatch(submitHelpDeskForm('helpdeskform', values));

        e.preventDefault();

        return new Promise((resolve, reject) => {
            const unsubscribe = store.subscribe(() => {

                const { helpDeskSubmitCompleted, ...extraParams } = store.getState().helpReducer;
                //debugger;
                unsubscribe();

                if (helpDeskSubmitCompleted) {
                    resolve();

                    this.setState({ buttonDisabled: 'disabled' });
                    
                    notify({
                        message: 'Help Desk Ticket has been submitted successfully. Our technical support will contact you at the phone number you provided. Thank you.',
                        position: {
                            my: 'center top',
                            at: 'center top'
                        }
                    }, 'success', 5000);
                    //this.setState({ popupVisible: false });
                } else {
                    notify({
                        message: extraParams.message,
                        position: {
                            my: 'center top',
                            at: 'center top'
                        }
                    }, 'error', 5000);
                    reject(extraParams.message);
                }

            });
        });
        
        
        
    }
}

function sendRequest(value) {
    const validEmail = 'test@test.com';
    return new Promise((resolve) => {
        setTimeout(function () {
            resolve(value === validEmail);
        }, 1000);
    });
}

function asyncValidation(params) {
    return sendRequest(params.value);
}

function mapStateToProps(state) {
    return {
        state: state.helpReducer
    };
}

export default HelpDeskForm;

const connectedHelpDeskForm = connect(mapStateToProps)(HelpDeskForm);
export { connectedHelpDeskForm as HelpDeskForm };